import React, { Component } from 'react'
import SystemHeader from './SystemHeader';

import moment from "moment";
import 'moment/locale/is';

import axios from 'axios';
import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

document.body.classList.add("admin");

export class StaffSjaSamskipti extends Component {

    state = { posts: [],skilabod: [], isLoaded: false, selectedid: this.props.match.params.id }
    componentDidMount () {

        axios.get('https://apiexpeda.pipp.is/admin_messages/' + this.props.match.params.id,{
            headers: {
                'Authorization': token
            }
        })
        .then(res => this.setState({ skilabod: res.data.data.tasks, isLoaded: true }));

        axios.get('https://apiexpeda.pipp.is/admin_messages',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.tasks, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('https://apiexpeda.pipp.is/adminsessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded,skilabod, selectedid} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <SystemHeader title="Samskipti" />
                    <div className="verkin">
                        {posts.map(post =>
                            <div className={getselected(post.id, selectedid) + post.read}  key={post.id}>
                                <div className="mynd"></div>
                                <div className="upplysingar">
                                    <h3><a href={"/admin/samskipti/" + post.id}>{post.title}</a></h3>
                                    <p>{post.name}</p>
                                    <p>{moment(post.deadline).format('Do MMM YYYY')}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="verkid">
                    {skilabod.map(post =>
                        <div key={post.id}>
                            <h3>{post.title}</h3>
                            <h4>{post.name}</h4>
                            <h4>{moment(posts.deadline).format('Do MMM YYYY H:mm:ss')}</h4>
                            <div className='skilabodin'>
                                <p>{post.description}</p>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
            )
        } else  {
            return ( <div>hleð...</div>)
        }
    }
}

function getselected(nid, vid) {
    var int_nid = parseInt(nid);
    var int_vid = parseInt(vid);
    if (int_nid === int_vid) {
        return "item selected ";
    } else {
        return "item ";
    }
}

export default StaffSjaSamskipti;