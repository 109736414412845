import React, { Component } from 'react'
import SystemHeader from './SystemHeader';

document.body.classList.add("admin");

export class Dashboard extends Component {
    render() {
        return (
            <div>
            <SystemHeader title="Yfirlit" />
            <div className="yfirlit">
                
            </div>
        </div>
        )
    }
}

export default Dashboard