import React, { Component } from 'react'
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Urgencies from './Urgencies'
import Topbar from '../templates/Topbar';
import Heildarskor from './user/components/Heildarskor_nytt';

import UserGrafMonthByID from './user/components/UserGrafMonthByID';

import { getToken, getSession, getRefreshToken, removeUserSession, setRefreshUserSession } from '../../Utils/Common';

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class Dashboard extends Component {
    state = { posts: [], isLoaded: false, userid: null };
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/user',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ userid: res.data.data.userid, isLoaded: true }))
        .catch(error => {
			const response = error.response;

            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('https://apiexpeda.pipp.is/sessions/'+session, json , {
                    headers: {
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { isLoaded, userid} = this.state;
        const { t } = this.props;
        if (isLoaded) {
            return (
                <div>
                    <Topbar title="menu.yfirlit" heiti=""/>
                    <main>
                        <div className="yfirlit">
                            <a className='skrakast_btn' href="/notandi/haexpert/skra"><strong>Skrá kast</strong><br/>Hér getur þú skráð kast</a>
                            <a className='lokakostum_btn' href="/notandi/haexpert"><strong>Loka köstum</strong><br/>Hér getur þú lokað eða eytt köstum</a>
                            <a className='samskipti_btn' href="/notandi/samskipti"><strong>Samskipti</strong><br/>Hér getur þú sent skilaboð </a>
                            <a className='timapantanir_btn' href="/notandi/timapantanir"><strong>Tímapantanir</strong><br/>Hér eru tímapantanir þínar </a>
                            <h3 className="section_heading">{t('menu.haexpert')}</h3>
                            <div className="alpha">
                                <Urgencies/>
                            </div>
                            <div className="gamma">
                                <h3>Tilfelli - Síðustu 6 mánuðir <Link to="/notandi/skyrsla"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                                <UserGrafMonthByID userid={userid} />
                            </div>

                            <Heildarskor/>
                        </div>
                    </main>
                </div>
            )
        } else {
            return (<div></div>);
        }
    }
}

export default withTranslation()(Dashboard);