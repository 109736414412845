import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import GetUnread from '../Views/user/components/GetUnread';
import MobileHeader from './MobileHeader';
import { withTranslation} from 'react-i18next';

import './Topbar.css';

export class Topbar extends Component {

    state = { show: true }
    toggle = () => this.setState((currentState) => ({show: !currentState.show}));

    render() {
        
        const { t, i18n } = this.props;
        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
            this.setState( { show: true } )
        };

        return (
            <div className={"topbar " + i18n.language}>
                <MobileHeader/>
                <h2>{this.props.heiti + " " + t(this.props.title)}</h2>
                <div className="userprofile">
                    <div className="user-icon">
                        <Link to="/notandi/minsida"></Link>
                        <span></span>
                    </div>
                </div>
                <div className="changeLanguage">
                    <div className='current' onClick={this.toggle}>{t('language')} <i className="fa-solid fa-caret-down"></i></div>
                    <ul className={this.state.show ? 'tungumal' : 'tungumal opna'}>
                        <li className="tung_is"><button type="button" onClick={() => changeLanguage('is')}>Íslenska</button></li>
                        <li className="tung_en"><button type="button" onClick={() => changeLanguage('en')}>English</button></li>
                    </ul>
                </div>
                <GetUnread/>
                
                <div className="skraskast_top"><a href="/notandi/haexpert/skra"><i className="fa-regular fa-square-plus"></i> {t('menu.skrakast')}</a></div>
            </div>
        )
    }
}

export default withTranslation()(Topbar);