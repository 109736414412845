import React, { Component } from 'react'
import { withTranslation} from 'react-i18next';
import axios from 'axios';
import { getToken } from '../../Utils/Common';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

const token = getToken();

export class Urgencies extends Component {

    state = { posts: [], isLoaded: false, Number: 5 }
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/urgency/5', {
            headers: { 
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { t } = this.props;
        const { posts, isLoaded, Number} = this.state;
        if (isLoaded) {
            return (
                <div className="urgencies list">
                    <h3>{t('home.acuteattacks', { Number })} <Link to="/notandi/HAExpert"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                    {posts.map(post =>
                        <div className="bradarkast" key={post.id}>
                            <div className={"dagur litur"+post.hex}>
                                <strong>{moment(post.registered).format('Do')}</strong>
                                {moment(post.registered).format('MMM')}
                            </div><div className="lysing">
                                <h3><a href={"/notandi/haexpert/uppfaera/" + post.id }>{post.ended}</a></h3>
                                <p><span>{t('advice')}:</span> {post.reaction}</p>
                            </div>
                        </div>
                    )}
                </div>
            )
        } else {
            return (
                <div className="urgencies">
                    <div className="urgency">{t('loading')}</div>
                </div>
            )
        }
    }
}

export default withTranslation()(Urgencies);