import axios from 'axios';

export const getUser = () => {
    const userStr = sessionStorage.getItem('admin_user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}

export const getRefreshToken = () => {
    return sessionStorage.getItem('admin_refreshtoken') || null;
}

export const getToken = () => {
    return sessionStorage.getItem('admin_token') || null;
}

export const getStarf = () => {
    return sessionStorage.getItem('starf') || null;
}

export const getName = () => {
    return sessionStorage.getItem('admin_fullname') || null;
}

export const getSession = () => {
    return sessionStorage.getItem('admin_session') || null;
}

export const removeAdminSession = () => {
    const session = sessionStorage.getItem('admin_session');
    const token = sessionStorage.getItem('admin_token');
    axios.delete('https://apiexpeda.pipp.is/adminsessions/' + session ,{
        headers: { 
            'Authorization': token
        }
    })
    
    sessionStorage.removeItem('admin_token');
    sessionStorage.removeItem('admin_refreshtoken');
    sessionStorage.removeItem('admin_user');
    sessionStorage.removeItem('admin_session');
    sessionStorage.removeItem('admin_fullname');
    sessionStorage.removeItem('starf');
}

/* 
    response.data.data.access_token, 
    response.data.data.refresh_token, 
    username.value, 
    response.data.data.session_id, 
    response.data.data.nafn, 
    response.data.data.starf 

    access_token: "YmQ5Y2FmYTg4MjdlOTUyZTQxNGExOTA0Y2IxYjA4ZjJkOGI1OThkMDIyOGZkMmRjMTY1ODUxNDIyOQ=="
    access_token_expires_in: 1200
    nafn: "Guðmundur R Einarsson"
    refresh_token: "Y2I1M2QxYjIzMjEwNDAxNTdkZjJkNjliZWJhMTU3Mzc1ODdiYjllMjRmYjgzODQzMTY1ODUxNDIyOQ=="
    refresh_token_expires_in: 1209600
    session_id: 60
    starf: 1

*/
export const setAdminSession = (token, refreshtoken, user, session, nafn, starf) => {
    sessionStorage.setItem('admin_token', token);
    sessionStorage.setItem('admin_refreshtoken', refreshtoken);
    sessionStorage.setItem('admin_user', JSON.stringify(user));
    sessionStorage.setItem('admin_session', session);
    sessionStorage.setItem('admin_fullname', nafn);
    sessionStorage.setItem('starf', starf);

    console.log(sessionStorage.getItem('admin_fullname') + " " + nafn);
    console.log(sessionStorage.getItem('admin_user') + " " + user);
    console.log(sessionStorage.getItem('admin_session') + " " + session);
    console.log(sessionStorage.getItem('admin_token') + " " + token);
    document.location.href = '/admin/stjornbord';
}

export const setRefreshUserSession = (token, refreshtoken) => {
    sessionStorage.setItem('admin_token', token);
    sessionStorage.setItem('admin_refreshtoken', refreshtoken);
    document.location.href = '/';
}