import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import SystemHeader from './SystemHeader';
import UserDropdown from './components/UserDropdown';

import moment from "moment";
import 'moment/locale/is';

import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class HAExpert extends Component {

    state = { posts: [], isLoaded: false, perpage: 0 }
    componentDidMount () {
        var config = {
            'Content-Type': 'application/json'
        }

        axios.get('https://apiexpeda.pipp.is/urgencies', {
            param: {
                per_page: 2
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('https://apiexpeda.pipp.is/adminsessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { t } = this.props;
        const { posts, isLoaded} = this.state;
        console.log(posts)
        if (isLoaded) {
            return (
                <div>
                    <SystemHeader title="menu.haexpert"/>
                    <main>
                        <UserDropdown/>
                        <div className="urgencies listinn">
                            {posts.map(post =>
                                <div className="urgency" key={post.id} style={{borderLeft: '5px solid #'+post.hex}}>
                                    <h3>{post.ended}</h3>
                                    <h4><span>Sjúklingur:</span> <Link to={"/admin/hae/" + post.userid} >{post.fullname}</Link></h4> 
                                    <h4><span>Skráð:</span> {moment(post.registered).format('Do MMMM  YYYY')}</h4>
                                    <p><span>Ráðgjöf:</span> <strong>{post.reaction}</strong></p>
                                </div>
                            )}
                        </div>
                    </main>
                </div>
            )
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

function athugaStodu(p,id) {
    if (p === 0) {
        let thisIsMyCopy = "<a href='/notandi/haexpert/Loka/"+id+"'>Loka kasti</a>"
        return <div className="content" dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    } else {
        let thisIsMyCopy = "<span>Kasti Lokið</span>";
        return <div className="content" dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    }
}

export default withTranslation()(HAExpert);