import React, { Component } from 'react'
import SystemHeader from './SystemHeader';
import BreytaLykilordi from './BreytaLykilordi';
import { getName,getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'
import axios from 'axios';

const Name = getName();
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();
const handleLogout = () => {
    removeAdminSession();
    window.location.reload();
}

export class userprofile extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/getuser',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.user, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('https://apiexpeda.pipp.is/adminsessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded } = this.state;
        if (isLoaded) {
            return (
                <div>
                    <SystemHeader title="Mín síða" />
                    <div className="yfirlit formid">
                        {Name} <button onClick={handleLogout} className="innskraning">Útskrá</button>
                        {posts.map(post =>
                            <div className="notandinn" key={post.id}>
                                <div><strong>Netfang:</strong> {post.netfang}</div>
                                <div><strong>Sími:</strong> {post.tel}</div>
                                <div><strong>Starfsheiti:</strong> {post.starfsheiti}</div>
                                <BreytaLykilordi uid={post.id}/>
                            </div>
                        )}
                    </div>
                </div>
            )
        } else{
            return (
            <div>
                <SystemHeader title="Mín síða" />
                <div className="yfirlit formid">
                    Næ í gögn...
                </div>
            </div>
            )
        }
    }
}

export default userprofile