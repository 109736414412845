import React, { Component } from 'react'
import axios from 'axios';
import Topbar from '../templates/Topbar';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getToken } from '../../Utils/Common';

import moment from "moment";
import 'moment/locale/is';

const token = getToken();

export class ViewSendTasks extends Component {

    state = { posts: [],skilabodin: [], isLoaded: false, selectedid: this.props.match.params.id}
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/tasks/' + this.props.match.params.id,{
            headers: {
                'Authorization': token
            }
        })
        .then(res => this.setState({ skilabodin: res.data.data.tasks, isLoaded: true }));

        axios.get('https://apiexpeda.pipp.is/messages/send',{
            headers: {
                'Authorization': token
            }
        })
        .then(res => this.setState({ posts: res.data.data.tasks, isLoaded: true }));
    }

    render() {
        const { t } = this.props;
        const { posts,skilabodin, isLoaded, selectedid} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <Topbar title="menu.samskipti" heiti="" />
                    <main>
                        <div className="verkin">
                            <ul className="skilabod">
                                <li><Link to="/notandi/samskipti">Til mín</Link></li>
                                <li><Link to="/notandi/samskipti/sent" className="selected">Frá mér</Link></li>
                                <li><Link to="/notandi/nysamskipti">Senda</Link></li>
                            </ul>
                            {posts.map(post =>
                                <div className={getselected(post.id, selectedid) + post.read} key={post.id}>
                                    <div className="mynd"></div>
                                    <div className="upplysingar">
                                        <h3><a href={"/notandi/samskipti/sentmessage/" + post.id}>{post.title}</a></h3>
                                        <p>{moment(post.deadline).format('Do MMM YYYY')}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="verkid">
                            {skilabodin.map(skilabod =>
                                <div key={skilabod.id}>
                                    <h3>{skilabod.title}</h3>
                                    <h4>{moment(skilabod.deadline).format('Do MMM YYYY H:mm:ss')}</h4>
                                    <div className='skilabodin'>
                                        <p>{skilabod.description}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </main>
                </div>
            )
        } else {
            return (
                <div>
                    <Topbar title={t('menu.samskipti')}/>
                    <main>
                        <h1 className="page-title">Öll skilaboð <a href="/notandi/nysamskipti">Senda skilaboð</a></h1>
                    </main>
                </div>
            )
        }
    }
}

function getselected(nid, vid) {
    var int_nid = parseInt(nid);
    var int_vid = parseInt(vid);
    if (int_nid === int_vid) {
        return "item selected ";
    } else {
        return "item ";
    }
}

export default withTranslation()(ViewSendTasks);