import React, { Component } from 'react'
import SystemHeader from './SystemHeader';

import moment from "moment";
import 'moment/locale/is';

import { Link } from 'react-router-dom';
import axios from 'axios';
import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

document.body.classList.add("admin");

export class Samskipti extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/admin_messages',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.tasks, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('https://apiexpeda.pipp.is/adminsessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <SystemHeader title="Samskipti" />
                    <div className="verkin">
                        {posts.map(post =>
                            <div className="item" key={post.id}>
                                <div className="mynd"></div>
                                <div className="upplysingar">
                                    <h3><Link to={"/admin/samskipti/" + post.id}>{post.title}</Link></h3>
                                    <p>{moment(post.deadline).format('Do MMM YYYY')}</p>
                                    <p>{post.name}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="verkid">
                        <h3>{posts[0].title}</h3>
                        <h4>{posts[0].name}</h4>
                        <h4>{moment(posts[0].deadline).format('Do MMM YYYY H:mm:ss')}</h4>
                        <div className='skilabodin'>
                            <p>{posts[0].description}</p>
                        </div>
                    </div>
                </div>
            )
        } else  {
            return ( <div>hleð...</div>)
        }
    }
}

function getKyn(p) {
    if (p === 1) {
        return 'Karlmaður';
    } else if (p === 2) {
        return 'Kvenmaður';
    } else {
        return 'Hán';
    }
}

export default Samskipti