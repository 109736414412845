import React, { Component } from 'react'

import axios from 'axios';
import { getToken } from  '../../../../Utils/admincommon';
import { withTranslation} from 'react-i18next';

const token = getToken();

export class PatientForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            name: 0,
            kyn: "",
            tel: "",
            ssn: "",
            netfang: "",
            allergies: "",
            mydr: "",
            myhealthcare: "",
            textiatakka: "Breyta Notenda",
        }

        this.setName = this.setName.bind(this);
        this.setNetfang = this.setNetfang.bind(this);
        this.setSSN = this.setSSN.bind(this);
        this.setKyn = this.setKyn.bind(this);
        this.setTel = this.setTel.bind(this);
        this.setAllergies = this.setAllergies.bind(this);
        this.setMYdr = this.setMYdr.bind(this);
        this.setMYhealthcare = this.setMYhealthcare.bind(this);
        this.setTextaATakka = this.setTextaATakka.bind(this);
    }

    componentDidMount () {

        var config = {
            'Content-Type': 'application/json',
            'Authorization': token
        }

        axios.get('https://apiexpeda.pipp.is/getpatient/'+this.props.userid, {
            param: {
                userid: this.props.userid
            },
            headers: config
        }, { crossdomain: true })
        .then(res => this.setState({
            isLoaded: true,
            user: this.props.userid,
            ssn: res.data.data.user[0].ssn,
            kyn: res.data.data.user[0].kyn,
            tel: res.data.data.user[0].tel,
            netfang: res.data.data.user[0].netfang,
            name: res.data.data.user[0].name,
            allergies: res.data.data.user[0].allergies,
            mydr: res.data.data.user[0].physician,
            myhealthcare: res.data.data.user[0].healthcare,
        } ))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    setName(event) {  this.setState({name: event.target.value}); }
    setNetfang(event) {  this.setState({netfang: event.target.value}); }
    setSSN(event) {  this.setState({ssn: event.target.value}); }
    setKyn(event) {  this.setState({kyn: event.target.value}); }
    setTel(event) {  this.setState({tel: event.target.value}); }
    setAllergies(event) {  this.setState({allergies: event.target.value}); }
    setMYdr(event) {  this.setState({mydr: event.target.value}); }
    setMYhealthcare(event) {  this.setState({myhealthcare: event.target.value}); }
    setTextaATakka(p) {  this.setState({textiatakka: p}); }

    handleSubmit(event) {
        event.preventDefault();
        try {
            fetch("https://apiexpeda.pipp.is/getpatient/"+event.target[5].value, {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    name: event.target[0].value,
                    netfang: event.target[1].value,
                    ssn: event.target[3].value,
                    kyn: event.target[4].value,
                    tel: event.target[2].value,
                    userid: event.target[5].value,
                    allergies: event.target[6].value,
                    mydr: event.target[7].value,
                    myhealthcare: event.target[8].value
                }),
            }).then((response) => {
                if (response.status === 201) {
                    window.location = "/admin/patients/" + event.target[5].value;
                }
            });

        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { isLoaded, name, netfang, tel, kyn, ssn, user, allergies, mydr, myhealthcare, textiatakka} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <div className="sjuklingsform">
                        <p><strong>Breyta upplýsingum</strong></p>
                        <form onSubmit={this.handleSubmit}>
                            <label htmlFor="name">Nafn</label>
                            <input type="text" placeholder="Nafn" value={name} id="name" onChange={this.setName} />
                            <label htmlFor="netfang">Netfang</label>
                            <input type="text" placeholder="Netfang" value={netfang} id="netfang" onChange={this.setNetfang} />
                            <label htmlFor="tel">Símanúmer</label>
                            <input type="text"  placeholder="Símanúmer" value={tel} id="tel" onChange={this.setTel} />
                            <label htmlFor="ssn">Kennitala</label>
                            <input type="text"  placeholder="Kennitala" value={ssn} id="ssn" onChange={this.setSSN}  />
                            <label htmlFor="kyn">Kyn</label>
                            <select value={kyn}  id="kyn" onChange={this.setKyn}>
                                <option value="1">Karlmaður</option>
                                <option value="2">Kvenmaður</option>
                                <option value="3">Hán</option>
                            </select>
                            <input type="hidden" value={user} id="user" readOnly />

                            <label htmlFor="ssn">Ofnæmi</label>
                            <input type="text"  placeholder="allergies" value={allergies} id="allergies" onChange={this.setAllergies}  />

                            <label htmlFor="ssn">Heimilislæknir</label>
                            <input type="text"  placeholder="mydr" value={mydr} id="mydr" onChange={this.setMYdr}  />

                            <label htmlFor="ssn">Heilsugæsla</label>
                            <input type="text"  placeholder="Kennitala" value={myhealthcare} id="myhealthcare" onChange={this.setMYhealthcare}  />

                            <button type="submit" className="buatilnotenda" id="uppfaeranotenda" onClick={() => this.setState({ textiatakka: "Er að uppfæra" })}> {textiatakka}</button>
                        </form>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}


export default withTranslation()(PatientForm);