import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import UserGraf from './UserGraf';
export class UserUrgencies extends Component {

    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {
        var config = {
            'Content-Type': 'application/json'
        }

        axios.get('https://apiexpeda.pipp.is/urgencies/'+this.props.userid + '/8', {
            param: {
                userid: this.props.userid
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <UserGraf
                        overuleg_six={posts.overuleg_six}
                        vaeg_six={posts.vaeg_six}
                        midlungs_six={posts.midlungs_six}
                        alvarlegt_six={posts.alvarlegt_six}
                        mansix={posts.mansix}
                        overuleg_five={posts.overuleg_five}
                        vaeg_five={posts.vaeg_five}
                        midlungs_five={posts.midlungs_five}
                        alvarlegt_five={posts.alvarlegt_five}
                        manfive={posts.manfive}
                        overuleg_four={posts.overuleg_four}
                        vaeg_four={posts.vaeg_four}
                        midlungs_four={posts.midlungs_four}
                        alvarlegt_four={posts.alvarlegt_four}
                        mantfour={posts.mantfour}
                        overuleg_three={posts.overuleg_three}
                        vaeg_three={posts.vaeg_three}
                        midlungs_three={posts.midlungs_three}
                        alvarlegt_three={posts.alvarlegt_three}
                        manthree={posts.manthree}
                        overuleg_two={posts.overuleg_two}
                        vaeg_two={posts.vaeg_two}
                        midlungs_two={posts.midlungs_two}
                        alvarlegt_two={posts.alvarlegt_two}
                        manttwo={posts.manttwo}
                        overuleg={posts.overuleg}
                        vaeg={posts.vaeg}
                        midlungs={posts.midlungs}
                        alvarlegt={posts.alvarlegt}
                        mantone={posts.mantone}
                    />
                </div>
            )
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

export default withTranslation()(UserUrgencies);