import React, { Component } from 'react';
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

import { getToken } from '../../Utils/Common';
const token = getToken();

export class HAEQualityOfLife extends Component {
    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {

        axios.get('https://apiexpeda.pipp.is/qualityoflife/all/', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ posts: res.data.data.qol, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        const { t } = this.props;
        if (isLoaded) {
            if (posts) {
                return (
                    <div>
                        <Topbar title="menu.lifsgaedi" heiti=""/>
                        <main>
                        <h1>Lífsgæða próf <Link to="/notandi/hae/lifsgaedi/new" className="addUrgency"><i className="fa-solid fa-square-plus"></i> Taka próf</Link></h1>
                        <table className='qualityoflife'>
                            <thead>
                                <tr>
                                    <th>Dagsetting</th>
                                    <th className='tc'>Verkir</th>
                                    <th className='tc'>Líkamleg virkni</th>
                                    <th className='tc'>Andleg líðan</th>
                                    <th className='tc'>Almenn líðan</th>
                                    <th className='tr'>Heildarskor</th>
                                </tr>
                            </thead>
                            <tbody>
                            {posts.map(post =>
                                <tr>
                                    <td className='grey'>{moment(post.createdon).format('Do MMM YYYY')}</td>
                                    <td className='tc'><strong className={getColor(100 - post.verkirtotal)}>{100 - post.verkirtotal}%</strong></td>
                                    <td className='tc'><strong className={getColorUp(post.virknitotal)}>{post.virknitotal}%</strong></td>
                                    <td className='tc'><strong className={getColorUp(post.andlegtotal)}>{post.andlegtotal}%</strong></td>
                                    <td className='tc'><strong className={getColorUp(post.almenntotal)}>{post.almenntotal}%</strong></td>
                                    <td className='tr'><strong className={getColorUp(post.score)}>{post.score}%</strong></td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        </main>
                    </div>
                )
            } else {
                return (
                    <div className="urgencies">
                        <h3>{t('menu.lifsgaedi')} <Link to="/notandi/hae/lifsgaedi/"><i className="fa-solid fa-ellipsis"></i></Link></h3>
                        <p>Ekkert próf hefur verið tekið</p>
                        <div className="nyttprof">
                            <a href='/notandi/hae/lifsgaedi/'>Taka próf</a>
                        </div>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

function getColor(p) {
    let mycolor = "litur_4";

    if (p <= 25 && p >= 0) {
        mycolor = "litur_0";
    } else if (p > 25 && p <= 50) {
        mycolor = "litur_1";
    } else if (p > 50 && p <= 75) {
        mycolor = "litur_2";
    } else if (p > 75 && p <= 99) {
        mycolor = "litur_3";
    } else if (p >= 100) {
        mycolor = "litur_4";
    }

    return mycolor;
}

function getColorUp(p) {
    let mycolor = "litur_0";

    if (p <= 25 && p >= 0) {
        mycolor = "litur_4";
    } else if (p > 25 && p <= 50) {
        mycolor = "litur_3";
    } else if (p > 50 && p <= 75) {
        mycolor = "litur_2";
    } else if (p > 75 && p <= 99) {
        mycolor = "litur_1";
    } else if (p >= 100) {
        mycolor = "litur_0";
    }

    return mycolor;
}

export default withTranslation()(HAEQualityOfLife);