import React, { Component } from 'react'
import DateTimePicker from 'react-datetime-picker';
import Topbar from '../templates/Topbar';
import axios from 'axios';
import './AddUrgency.css';

import moment from "moment";
import 'moment/locale/is';

import { getToken, getKyn } from '../../Utils/Common';

const token = getToken();
const kynid = getKyn();

export class AddUrgency extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: new Date(),
            Started: 0,
            counter: 0,
            points_ll: 0,
            points_rl: 0,
            points_lh: 0,
            points_rh: 0,
            points_oh: 0,
            points_ov: 0,
            points_kvidur: 0,
            points_face: 0,
            points_hne: 0,
            points_vhne: 0,
            points_munnhol: 0,
            points_ondun: 0,
            points_kynfaeri: 0,
            Cause: 1,
            points: 0,
            fjoldi: 0,
            DiseaseActivity: 1,
            hex: 1,
            reaction: 1,
            actiontaken: 9,
            message: "",
            messageCss: "",
            skilabodeitt: "",
            skilabodtvo: "",
            style: "close'",
            stylemunnhol: "close",
            styleKvidur: "close",
            styleOndun: "close",
            styleKynfaeri: "close",
            styleLLeg: "close",
            styleRLeg: "close",
            styleLHand: "close",
            styleRHand: "close",
            styleLOl: "close",
            styleROl: "close",
            styleHne: "close",
            styleVHne: "close",
            jsonstring: "",
        }

        this.getTotal = this.getTotal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setPointsface = this.setPointsface.bind(this);
        this.setPoints_munnhol = this.setPoints_munnhol.bind(this);
        this.setPoints_kvidur = this.setPoints_kvidur.bind(this);
        this.setPoints_ondun = this.setPoints_ondun.bind(this);
        this.setPoints_kynfaeri = this.setPoints_kynfaeri.bind(this);
        this.setPoints_ll = this.setPoints_ll.bind(this);
        this.setPoints_rl = this.setPoints_rl.bind(this);
        this.setPoints_lh = this.setPoints_lh.bind(this);
        this.setPoints_rh = this.setPoints_rh.bind(this);
        this.setPoints_lol = this.setPoints_lol.bind(this);
        this.setPoints_rol = this.setPoints_rol.bind(this);
        this.setPoints_hne = this.setPoints_hne.bind(this);
        this.setPoints_vhne = this.setPoints_vhne.bind(this);

        this.setStarted = this.setStarted.bind(this);
        this.setCause = this.setCause.bind(this);
        this.setValue = this.setValue.bind(this);

        this.setOndunStyle = this.setOndunStyle.bind(this);
        this.setKynfaeriStyle = this.setKynfaeriStyle.bind(this);
        this.setLLegStyle = this.setLLegStyle.bind(this);
        this.setMunnholStyle = this.setMunnholStyle.bind(this);
        this.setStyle = this.setStyle.bind(this);
        this.setKvidurStyle = this.setKvidurStyle.bind(this);
        this.setLHandStyle = this.setLHandStyle.bind(this);
        this.setRHandStyle = this.setRHandStyle.bind(this);
        this.setLOlStyle = this.setLOlStyle.bind(this);
        this.setROlStyle = this.setROlStyle.bind(this);
        this.setHneStyle = this.setHneStyle.bind(this);
        this.setVHneStyle = this.setVHneStyle.bind(this);
        this.setTotal = this.setTotal.bind(this);
        this.setMessage = this.setMessage.bind(this);
    }

    setValue(p) { this.setState({value: p});this.getTotal(); }
    setStarted(p) { this.setState({Started: p.target.value}); this.getTotal(); }
    setCause(p) { this.setState({Cause: p.target.value}); this.getTotal(); }

    // Points
    setPointsface(p) { this.setState({points_face: p}); this.getTotal(); this.closeAllSelection(); }
    setTotal(p) { this.setState({points: p}); }
    setPoints_munnhol(p) {  this.setState({points_munnhol: p}); this.getTotal(); this.closeAllSelection(); }
    setPoints_kvidur(p) {  this.setState({points_kvidur: p}); this.getTotal(); this.closeAllSelection(); }
    setPoints_ondun(p) { this.setState({points_ondun: p});this.getTotal(); this.closeAllSelection(); }
    setPoints_kynfaeri(p) { this.setState({points_kynfaeri: p});this.getTotal(); this.closeAllSelection(); }
    setPoints_ll(p) { this.setState({points_ll: p});this.getTotal(); this.closeAllSelection(); }
    setPoints_rl(p) {  this.setState({points_rl: p}); this.getTotal(); this.closeAllSelection();}
    setPoints_lh(p) { this.setState({points_lh: p});this.getTotal(); this.closeAllSelection();}
    setPoints_rh(p) { this.setState({points_rh: p});this.getTotal(); this.closeAllSelection();}
    setPoints_lol(p) { this.setState({points_ov: p});this.getTotal(); this.closeAllSelection();}
    setPoints_rol(p) { this.setState({points_oh: p});this.getTotal(); this.closeAllSelection();}
    setPoints_hne(p) { this.setState({points_hne: p});this.getTotal(); this.closeAllSelection();}
    setPoints_vhne(p) { this.setState({points_vhne: p});this.getTotal(); this.closeAllSelection();}

    setKvidurStyle(p) {this.setState({styleKvidur:p});}
    setOndunStyle(p) {this.setState({styleOndun:p});}
    setKynfaeriStyle(p) {this.setState({styleKynfaeri:p})}
    setLLegStyle(p) { this.setState( {styleLLeg:p} )}
    setRLegStyle(p) { this.setState( {styleRLeg:p} )}
    setMunnholStyle(p) { this.setState( {stylemunnhol:p})}
    setStyle(p) { this.setState({style:p})}
    setLHandStyle(p) {this.setState({styleLHand:p});}
    setRHandStyle(p) {this.setState({styleRHand:p});}
    setLOlStyle(p) {this.setState({styleLOl:p});}
    setROlStyle(p) {this.setState({styleROl:p});}
    setHneStyle(p) {this.setState({styleHne:p});}
    setVHneStyle(p) {this.setState({styleVHne:p});}

    setMessage(p) {
        this.setState({message:p, messageCss: "on"});
    }

    closeAllSelection() {
        this.setState({
            style:"close",
            styleKvidur:"close",
            styleOndun:"close",
            styleKynfaeri:"close",
            styleLLeg:"close",
            styleRLeg:"close",
            stylemunnhol:"close",
            styleLHand:"close",
            styleRHand:"close",
            styleLOl:"close",
            styleROl:"close",
            styleHne:"close",
            styleVHne:"close",
        });
    }

    getTotal() {
        let totalF = 0;
        let totalP =  this.state.points_ll + this.state.points_rl + this.state.points_lh + this.state.points_rh + this.state.points_ov + this.state.points_oh + this.state.points_kvidur + this.state.points_face + this.state.points_hne + this.state.points_vhne + this.state.points_munnhol + this.state.points_ondun + this.state.points_kynfaeri;
        let jsonstringtxt =  "{" + this.state.points_ll + ", "+ this.state.points_rl + ", "+ this.state.points_lh + ", "+ this.state.points_rh + ", "+ this.state.points_oh + ", "+ this.state.points_ov + ", "+ this.state.points_kvidur + ", "+ this.state.points_face + ", "+ this.state.points_hne + ", "+ this.state.points_vhne +", "+ this.state.points_munnhol + ", "+ this.state.points_ondun + ", "+ this.state.points_kynfaeri + "}";
        let erll = 0;
        let errl = 0;
        let erov = 0;
        let eroh = 0;
        let erlh = 0;
        let errh = 0;
        let erkvidur = 0;
        let erface = 0;
        let erhne = 0;
        let ervhne = 0;
        let ermunnhol = 0;
        let erondun = 0;
        let erkynfaeri = 0;

        let started_val = this.state.Started;
        if (started_val <= 3) {
            totalP = totalP+1;
        } else if (started_val >= 4 && started_val <= 7) {
            totalP = totalP+2;
        } else {
            totalP = totalP+3;
        }

        if (this.state.points_ll === 0) { erll = 0; } else { erll = 1;}
        if (this.state.points_rl === 0) { errl = 0; } else { errl = 1;}
        if (this.state.points_lh === 0) { erlh = 0; } else { erlh = 1;}
        if (this.state.points_rh === 0) { errh = 0; } else { errh = 1;}
        if (this.state.points_ov === 0) { erov = 0; } else { erov = 1;}
        if (this.state.points_oh === 0) { eroh = 0; } else { eroh = 1;}
        if (this.state.points_kvidur === 0) { erkvidur = 0; } else { erkvidur = 1;}
        if (this.state.points_face === 0) { erface = 0; } else { erface = 1;}
        if (this.state.points_hne === 0) { erhne = 0; } else { erhne = 1;}
        if (this.state.points_vhne === 0) { ervhne = 0; } else { ervhne = 1;}
        if (this.state.points_munnhol === 0) { ermunnhol = 0; } else { ermunnhol = 1;}
        if (this.state.points_ondun === 0) { erondun = 0; } else { erondun = 1;}
        if (this.state.points_kynfaeri === 0) { erkynfaeri = 0; } else { erkynfaeri = 1;}

        totalF = erll + errl + erlh + errh + erov + eroh + erkvidur + erface + erhne + ervhne + ermunnhol + erondun + erkynfaeri;

        this.setState({points: totalP, fjoldi: totalF, jsonstring: jsonstringtxt })
        //this.state.points = totalP;
        //this.state.fjoldi = totalF;
        //this.state.jsonstring = jsonstringtxt;

        if(totalP === 2) {
            this.setState({DiseaseActivity: 1, reaction: 1, hex: 1, skilabodeitt: "Óveruleg", skilabodtvo: "Hvíld" })
            //this.state.DiseaseActivity = 1;
            //this.state.reaction = 1;
            //this.state.hex = 1;
            //this.state.skilabodeitt = "Óveruleg";
            //this.state.skilabodtvo = "Hvíld";
        } else if (totalP === 3) {
            this.setState({DiseaseActivity: 2, reaction: 2, hex: 2, skilabodeitt: "Væg", skilabodtvo: "Hvíld og fá ráðgjöf" })
            //this.state.DiseaseActivity = 2;
            //this.state.reaction = 2;
            //this.state.hex = 2;
            //this.state.skilabodeitt = "Væg";
            //this.state.skilabodtvo = "Hvíld og fá ráðgjöf";
        } else if (totalP === 4) {
            this.setState({DiseaseActivity: 3, reaction: 3, hex: 3, skilabodeitt: "Miðlungs", skilabodtvo: "Nota Firazyr" })
            //this.state.DiseaseActivity = 3;
            //this.state.reaction = 3;
            //this.state.hex = 3;
            //this.state.skilabodeitt = "Miðlungs";
            //this.state.skilabodtvo = "Nota Firazyr";
        } else if (totalP > 4) {
            this.setState({DiseaseActivity: 4, reaction: 4, hex: 4, skilabodeitt: "Alvarleg", skilabodtvo: "Nota Cinryze eða Firazyr" })
            //this.state.DiseaseActivity = 4;
            //this.state.reaction = 4;
            //this.state.hex = 4;
            //this.state.skilabodeitt = "Alvarleg";
            //this.state.skilabodtvo = "Nota Cinryze eða Firazyr";
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.getTotal();
        const jsonbody = {
            registered: moment(this.state.value).format('yyyy-MM-DD'),
            Started: this.state.Started,
            Cause: this.state.Cause,
            value: this.state.value,
            points: this.state.points,
            hex: this.state.hex,
            DiseaseActivity: this.state.DiseaseActivity,
            reaction: this.state.reaction,
            actiontaken: this.state.actiontaken,
            jsonstring: this.state.jsonstring,
        };
        const customConfig = {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }, crossdomain: true
        };
        axios.post('https://apiexpeda.pipp.is/urgency', jsonbody, customConfig )
        .then(res => {
            if (res.data.statusCode === 201) {
                window.location = "/notandi/haexpert/uppfaera/" + res.data.data.returned_id;
            } else {
                console.log("Villa: " + res.data.statusCode)
            }
         })
        .catch(error => {
			const response = error.response;
            console.log(response)
        })
    };

    render() {
        //Tók út ,points,DiseaseActivity, hex,reaction
        const { 
                value, 
            Started, points_ll, points_rl,points_lh,points_rh,points_oh,points_ov,points_kvidur,points_face,points_hne,points_vhne,points_munnhol,points_ondun,points_kynfaeri,Cause,fjoldi,message,messageCss,skilabodeitt, skilabodtvo,
            style,
            stylemunnhol,
            styleKvidur,
            styleOndun,
            styleKynfaeri,
            styleLLeg,
            styleRLeg,
            styleLHand,
            styleRHand,
            styleLOl,
            styleROl,
            styleHne,
            styleVHne,
        } = this.state;

        return (
            <div>
                <Topbar title="menu.haexpert" heiti=""/>
                <main> 
                    <h1 className="skrakast">Skrá kast</h1>
                    <React.StrictMode>
                    <form onSubmit={this.handleSubmit}>
                        <div className="skra_mainwin">
                        <h2 className="skrakast_upplysingar">Merktu inn á myndina hvar kastið er í líkamanum</h2>
                            <div className="merking">
                                <div className={"likami " + getLikaman(kynid) + " " + fjoldi}>
                                    <div className={"merki hofud val_"+ points_face} id="merkjaHofud" onClick={() => this.setStyle('opid')}><p className="tip">Andlit og varir</p></div>
                                    <div className={"merki munnhol val_"+ points_munnhol} onClick={() => this.setMunnholStyle('opid')} ><p className="tip">Tunga og háls</p></div>
                                    <div className={"merki ondunarfaeri val_"+ points_ondun}  onClick={() => this.setOndunStyle('opid')}><p className="tip">Öndunarfæri</p></div>
                                    <div className={"merki meltingarfaeri val_"+ points_kvidur} onClick={() => this.setKvidurStyle('opid')}><p className="tip">Meltingarfæri</p></div>
                                    <div className={"merki kynfaeri val_"+ points_kynfaeri} onClick={() => this.setKynfaeriStyle('opid')}><p className="tip">Kynfæri</p></div>
                                    <div className={"merki vinstrihond val_"+ points_lh} onClick={() => this.setLHandStyle('opid')}><p className="tip">Vinstri Lófi</p></div>
                                    <div className={"merki haegrihond val_"+ points_rh} onClick={() => this.setRHandStyle('opid')}><p className="tip">Hægri Lófi</p></div>
                                    <div className={"merki vinstrio val_"+ points_ov} onClick={() => this.setLOlStyle('opid')}><p className="tip">Vinstri hönd</p></div>
                                    <div className={"merki haegrio val_"+ points_oh} onClick={() => this.setROlStyle('opid')}><p className="tip">Hægri hönd</p></div>
                                    <div  className={"merki hne vinstri val_"+ points_vhne} onClick={() => this.setVHneStyle('opid')}><p className="tip">Vinstri Hné</p></div>
                                    <div  className={"merki hne val_"+ points_hne} onClick={() => this.setHneStyle('opid')}><p className="tip">Hægri Hné</p></div>
                                    <div className={"merki vinstrifotur val_"+ points_ll} onClick={() => this.setLLegStyle('opid')}><p className="tip">Vinstri fótur</p></div>
                                    <div className={"merki haegrifotur val_"+ points_rl} onClick={() => this.setRLegStyle('opid')}><p className="tip">Hægri fótur</p></div>

                                    <div className={"lidan hofud " + style}>
                                        <div>Andlit og varir</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPointsface(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPointsface(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPointsface(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPointsface(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPointsface(4)}></i>
                                        <div className="loka" onClick={() => this.setStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan munnhol " + stylemunnhol}>
                                        <div>Tunga og háls</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_munnhol(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_munnhol(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_munnhol(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_munnhol(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_munnhol(4)}></i>
                                        <div className="loka" onClick={() => this.setMunnholStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan kvidur " + styleKvidur}>
                                        <div>Meltingarfæri</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_kvidur(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_kvidur(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_kvidur(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_kvidur(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_kvidur(4)}></i>
                                        <div className="loka" onClick={() => this.setKvidurStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan ondun " + styleOndun}>
                                        <div>Öndunarfæri</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_ondun(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_ondun(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_ondun(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_ondun(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_ondun(4)}></i>
                                        <div className="loka" onClick={() => this.setOndunStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan kynfaeri " + styleKynfaeri}>
                                        <div>Kynfæri</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_kynfaeri(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_kynfaeri(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_kynfaeri(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_kynfaeri(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_kynfaeri(4)}></i>
                                        <div className="loka" onClick={() => this.setKynfaeriStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan vistrifotur " + styleLLeg}>
                                        <div>Vinstri fótur</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_ll(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_ll(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_ll(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_ll(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_ll(4)}></i>
                                        <div className="loka" onClick={() => this.setLLegStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan haegrifotur " + styleRLeg}>
                                        <div>Hægri fótur</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_rl(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_rl(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_rl(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_rl(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_rl(4)}></i>
                                        <div className="loka" onClick={() => this.setRLegStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan vistrihond " + styleLHand}>
                                        <div>Vinstri Lófi</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_lh(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_lh(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_lh(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_lh(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_lh(4)}></i>
                                        <div className="loka" onClick={() => this.setLHandStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan haegrihond " + styleRHand}>
                                        <div>Hægri Lófi</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_rh(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_rh(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_rh(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_rh(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_rh(4)}></i>
                                        <div className="loka" onClick={() => this.setRHandStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan vistrio " + styleLOl}>
                                        <div>Vinstri hönd</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_lol(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_lol(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_lol(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_lol(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_lol(4)}></i>
                                        <div className="loka" onClick={() => this.setLOlStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan haegrio " + styleROl}>
                                        <div>Hægri hönd</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_rol(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_rol(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_rol(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_rol(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_rol(4)}></i>
                                        <div className="loka" onClick={() => this.setROlStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan hne " + styleHne}>
                                        <div>Hægri hné</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_hne(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_hne(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_hne(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_hne(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_hne(4)}></i>
                                        <div className="loka" onClick={() => this.setHneStyle('close')}>x</div>
                                    </div>
                                    <div className={"lidan hne vinstri " + styleVHne}>
                                        <div>Vinstri hné</div>
                                        <i className="fa-regular fa-face-smile" title="Eðlileg" onClick={() => this.setPoints_vhne(0)}></i>
                                        <i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi" onClick={() => this.setPoints_vhne(1)}></i>
                                        <i className="fa-regular fa-face-frown-slight" title="Væg bólga" onClick={() => this.setPoints_vhne(2)}></i>
                                        <i className="fa-regular fa-face-anguished" title="Miðlungs bólga" onClick={() => this.setPoints_vhne(3)}></i>
                                        <i className="fa-regular fa-face-confounded" title="Alvarleg bólga" onClick={() => this.setPoints_vhne(4)}></i>
                                        <div className="loka" onClick={() => this.setVHneStyle('close')}>x</div>
                                    </div>
                                    <input type="hidden" name="andlit" id="andlit" value={points_face} />
                                </div>
                                <h4 className="litakodakerfid">Litakóðakerfið</h4>
                                <div className="utskyring"><i className="fa-regular fa-face-smile" title="Eðlileg"></i> <div>Eðlileg</div></div>
                                <div className="utskyring"><i className="fa-regular fa-face-expressionless" title="Óveruleg óþægindi"></i> <div>Óveruleg óþægindi</div></div>
                                <div className="utskyring"><i className="fa-regular fa-face-frown-slight" title="Væg bólga"></i> <div>Væg bólga</div></div>
                                <div className="utskyring"><i className="fa-regular fa-face-anguished" title="Miðlungs bólga"></i> <div>Miðlungs bólga</div></div>
                                <div className="utskyring"><i className="fa-regular fa-face-confounded" title="Alvarleg bólga"></i> <div>Alvarleg bólga</div></div>
                            </div>
                        </div><div className="skra_upplysingar">
                            <h3 className="page-title">Dagsetning </h3>
                            <div>
                                <DateTimePicker  onChange={(e) => this.setValue(e)} value={value} format="y-MM-dd" disableClock={null} />
                            </div>
                            <h3 className="page-title">Hversu langt er síðan kastið byrjaði? (klst)</h3>
                            <select name="Started" value={Started}  onChange={(e) => this.setStarted(e)}> 
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">+7 klst</option>
                            </select>
                            <h3 className="page-title">Hvað telur þú að hafi komið kasti af stað?</h3>
                            <select name="Cause" value={Cause}  onChange={(e) => this.setCause(e)}>
                                <option value="1">Svefnleysi</option>
                                <option value="2">Spenna/kvíði</option>
                                <option value="3">Aðgerð</option>
                                <option value="4">Slys</option>
                                <option value="5">Áverki </option>
                                <option value="6">Sýking/kvef</option>
                                <option value="7">Lyf</option>
                                <option value="8">Óþekkt</option>
                                <option value="9">Annað</option>
                            </select><br/>

                            <p><button type="submit" className="buatilnotenda">Skrá bráðakasts</button></p>

                            <div className={"message " + messageCss}>
                                <div className="skilabod">
                                    {message ? <p>{message}</p> : null}
                                    Þetta kast er: <strong>{skilabodeitt}</strong><br/>
                                    Þú þart að: <br/>
                                    {skilabodtvo}<br/><br/>
                                    <a href="/notandi/haexpert">Loka</a>
                                </div>
                            </div>
                        </div>
                    </form>
                    </React.StrictMode>
                </main>
            </div>
        )
    }
}

function getLikaman(kynid) {

    if (kynid === "1") {
        return "kk";
    } else if (kynid === "2") {
        return "kvk";
    } else if (kynid === "3") {
        return "annad";
    }
}



export default AddUrgency

