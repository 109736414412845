import axios from 'axios';

export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
}

export const getCode = () => {
    return sessionStorage.getItem('otpcode') || null;
}

export const getPhonenum = () => {
    return sessionStorage.getItem('phonenum') || null;
}

export const getRefreshToken = () => {
    return sessionStorage.getItem('refreshtoken') || null;
}

export const getToken = () => {
    return sessionStorage.getItem('token') || null;
}

export const getKyn = () => {
    return sessionStorage.getItem('kyn') || null;
}

export const getName = () => {
    return sessionStorage.getItem('fullname') || null;
}

export const getSession = () => {
    return sessionStorage.getItem('session') || null;
}

export const removeUserSession = () => {
    const session = sessionStorage.getItem('session');
    const token = sessionStorage.getItem('token');
    axios.delete('https://apiexpeda.pipp.is/sessions/' + session ,{
        headers: { 
            'Authorization': token
        }
    })
    
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('refreshtoken');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('session');
    sessionStorage.removeItem('fullname');
    sessionStorage.removeItem('kyn');
    sessionStorage.removeItem('netfang');
    window.location.reload();
}

export const setUserSession = (token, refreshtoken, user, session, fullname, kyn) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshtoken', refreshtoken);
    sessionStorage.setItem('user', JSON.stringify(user));
    sessionStorage.setItem('session', session);
    sessionStorage.setItem('fullname', fullname);
    sessionStorage.setItem('kyn', kyn);
    window.location.reload();
}

export const setOTPCode = (code,phonenum) => {
    sessionStorage.setItem('otpcode', code);
    sessionStorage.setItem('phonenum', phonenum);
}

export const removeOTPCode = () => {
    sessionStorage.removeItem('otpcode');
    sessionStorage.removeItem('phonenum');
}

export const setRefreshUserSession = (token, refreshtoken) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('refreshtoken', refreshtoken);
    window.location.reload();
}