import React, { useState } from 'react';
import SystemHeader from './SystemHeader';

function RegisterPatient(props) {
    const [name, setName] = useState("");
    const [netfang, setNetfang] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [tel, setTel] = useState("");
    const [ssn, setssn] = useState("");
    const [kyn, setkyn] = useState(1);
    const [message, setMessage] = useState("");
    const [errormessage, setErrorMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let res = await fetch("https://apiexpeda.pipp.is/users", {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                crossdomain: true,
                body: JSON.stringify({
                    fullname: name,
                    username: username,
                    password: password,
                    netfang: netfang,
                    ssn: ssn,
                    kyn: kyn,
                    tel: tel,
                }),
            });

            let resJson = await res.json();
            console.log(resJson);
            if (res.status === 201) {
                setName("");
                setPassword("");
                setNetfang("");
                setUsername("");
                setTel("");
                setssn("");
                setkyn("");
                setMessage("Þetta gekk allt eins og í sögu!");
        } else {
            setErrorMessage("Ekki tókst að búa til notanda!");
            //setMessage("Ekki tókst að búa til notanda!");
        }
    } catch (err) {
        console.log(err);
        setErrorMessage("Villa kom upp!");
    }
};

    return (
        <div>
            <SystemHeader title="Skrá sjúkling" />
            <div className="yfirlit formid">
                <form onSubmit={handleSubmit}>
                    <input type="text" value={name} placeholder="Nafn" onChange={(e) => setName(e.target.value)} />
                    <input type="text" value={netfang} placeholder="Netfang" onChange={(e) => setNetfang(e.target.value)} />
                    <input type="text" value={tel} placeholder="Símanúmer" onChange={(e) => setTel(e.target.value)} />
                    <input type="text" value={ssn} placeholder="Kennitala" onChange={(e) => setssn(e.target.value)} />
                    <select value={kyn} onChange={(e) => setkyn(e.target.value)} >
                        <option value="1">Karlmaður</option>
                        <option value="2">Kvenmaður</option>
                        <option value="3">Hán</option>
                    </select>
                    <br/><br/>
                    <input type="text" value={username} placeholder="Notendanafn" onChange={(e) => setUsername(e.target.value)} />
                    <input type="password" value={password} placeholder="Lykilorð" onChange={(e) => setPassword(e.target.value)} />
                    <div className="nyskramessage">{message ? <p>{message}</p> : null}</div>
                    <div className="nyskraerrormessage">{errormessage ? <p>{errormessage}</p> : null}</div>
                    <button type="submit" className="buatilnotenda">Búa til!</button>
                </form>
            </div>
        </div>
    );
}

export default RegisterPatient;