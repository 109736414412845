function PartnerProgram() {
    return (
        <main>
            <div className="main">
                <h3 className="yfirfyrirsogn">Expeda</h3>
                <h2 className="fyrirsogn">Partner program</h2>
                <h3>The focus of the Expeda Partner Program is:</h3>
                <p><strong>Professional Collaboration</strong></p>
                <p>Expeda is dedicated to work with those that are committed to make new and improved Clinical Decision Support Systems. Expeda’s contribution towards such innovative approaches is considerable offering its professional knowledge in IT, medicine and business. An integral part of our proactive partner program is that Expeda grants its partners with a low cost user license to its Artificial Intelligence Web enabled IT platform. This includes the knowledge capturing tools that bring new expert medical knowledge to the global audience in a fast and cost effective way, without major capital investment</p>
                <p><strong>Partner Framework </strong></p>
                <p>The partner program is confined to the ICD-10 classification of allergic and rheumatic diseases (<a href="https://web.archive.org/web/20200221193252/http://www.who.int/classifications/icd/en/"><strong>ICD-10 classification</strong></a>)</p>
            </div>
        </main>
    )
}

export default PartnerProgram;