import React, { Component } from "react";
import Chart from "react-apexcharts";

class UserGrafStadsetting extends Component {
    constructor(props) {
        super(props);
        let overuleg_six = this.props.overuleg_six;
        let overuleg_five = this.props.overuleg_five;
        let overuleg_four = this.props.overuleg_four;
        let overuleg_three = this.props.overuleg_three;
        let overuleg_two = this.props.overuleg_two;
        let overuleg = this.props.overuleg;

        let vaeg_six = this.props.vaeg_six;
        let vaeg_five = this.props.vaeg_five;
        let vaeg_four = this.props.vaeg_four;
        let vaeg_three = this.props.vaeg_three;
        let vaeg_two = this.props.vaeg_two;
        let vaeg = this.props.vaeg;

        let midlungs_six = this.props.midlungs_six;
        let midlungs_five = this.props.midlungs_five;
        let midlungs_four = this.props.midlungs_four;
        let midlungs_three = this.props.midlungs_three;
        let midlungs_two = this.props.midlungs_two;
        let midlungs = this.props.midlungs;

        let alvarlegt_six = this.props.alvarlegt_six;
        let alvarlegt_five = this.props.alvarlegt_five;
        let alvarlegt_four = this.props.alvarlegt_four
        let alvarlegt_three = this.props.alvarlegt_three;
        let alvarlegt_two = this.props.alvarlegt_two;
        let alvarlegt = this.props.alvarlegt;

        let heild_six = overuleg_six + vaeg_six + midlungs_six + alvarlegt_six;
        let heild_five = overuleg_five + vaeg_five + midlungs_five + alvarlegt_five;
        let heild_four = overuleg_four + vaeg_four + midlungs_four + alvarlegt_four;
        let heild_three = overuleg_three + vaeg_three + midlungs_three + alvarlegt_three;
        let heild_two = overuleg_two + vaeg_two + midlungs_two + alvarlegt_two;
        let heild = overuleg + vaeg + midlungs + alvarlegt;

        this.state = {
            series: [{
                name: 'Óveruleg',
                type: 'line',
                data: [overuleg_six, overuleg_five, overuleg_four, overuleg_three, overuleg_two, overuleg]
            }, {
                name: 'Væg',
                type: 'line',
                data: [vaeg_six, vaeg_five, vaeg_four, vaeg_three, vaeg_two, vaeg]
            }, {
                name: 'Miðlungs',
                type: 'line',
                data: [midlungs_six, midlungs_five, midlungs_four, midlungs_three, midlungs_two, midlungs]
            }, {
                name: 'Alvarleg',
                type: 'line',
                data: [alvarlegt_six,alvarlegt_five,alvarlegt_four,alvarlegt_three, alvarlegt_two, alvarlegt]
            }, {
                name: 'Heildar köst',
                type: 'column',
                data: [heild_six, heild_five, heild_four, heild_three, heild_two, heild]
            }],
            options: {
                colors: ['#4dab15', '#9cb803', '#f1820b', '#b80000', '#00bcc7'],
                chart: {
                    id: "basic-bar",
                    type: 'line',
                    height: 380,
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        columnWidth:'30%',
                    }
                },
                dataLabels: {
                    enabled: true,
                    enabledOnSeries: [0,1, 2, 3, 4],
                    offsetY: 3,
                    background: {
                        padding: 4,
                    }
                },
                stroke: {
                    width: [4,4,4,4,0]
                },
                xaxis: {
                    categories: ['Ágúst', 'September', 'Október', 'Nóvember', 'Desember', 'Janúar'],
                },
                yaxis: {
                    title: {
                        text: 'Fjöldi kasta'
                    },
                    range: 1,
                    decimalsInFloat: 0,
                },
                legend: {
                    position: 'bottom'
                }
            },
        };
    }

    render() {
        return (
            <div className="app">
            <div className="row">
                <div className="mixed-chart">
                <Chart
                    options={this.state.options}
                    series={this.state.series}
                    type="line" height={380}
                />
                </div>
            </div>
            </div>
        );
    }
}

export default UserGrafStadsetting;