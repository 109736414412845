import React from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom';

//Routes
import PublicRoute from './Utils/PublicRoute';
import PrivateRoute from './Utils/PrivateRoute';
import AdminRoute from './Utils/AdminRoute';

//Layout
import AdminSystem from './components/layout/AdminSystem';
import UserSystem from './components/layout/UserSystem';
import WebSite from './components/layout/WebSite';

//Admin routes
import AdminDashboard from './components/Views/admin/Dashboard';
import AdminLogin from './components/Views/AdminLogin';
import Patients from './components/Views/admin/Patients';
import Patient from './components/Views/admin/Patient';
import RegisterPatient from './components/Views/admin/RegisterPatient';
import userprofile from './components/Views/admin/UserProfile';
import HAE from './components/Views/admin/HAExpert';
import Staff from './components/Views/admin/Starfsmenn';
import HAEUser from './components/Views/admin/HAEUser';
import StaffSamskipti from './components/Views/admin/Samskipti';
import StaffSjaSamskipti from './components/Views/admin/StaffSjaSamskipti';
import NySamskipti from './components/Views/admin/Nysamskipti';
import QOL from './components/Views/admin/Qol';

//User routes
import Dashboard from './components/Views/Dashboard';
import User from './components/Views/User';
import HAExpert from './components/Views/HAExpert';
import AddUrgency from './components/Views/AddUrgency';
import CloseUrgency from './components/Views/CloseUrgency';
import EditUrgency from './components/Views/EditUrgency';

import Skyrsla from './components/Views/Skyrsla';
import Tasks from './components/Views/Tasks';
import NewTask from './components/Views/NewTask';
import ViewTasks from './components/Views/ViewTasks';
import ViewSendTasks from './components/Views/ViewSendTask';
import SendMessages from './components/Views/SendMessages';

import HAEQualityOfLife from './components/Views/HAE_quality_of_life';
import HAEQualityOfLifeAll from './components/Views/HAE_quality_of_life_all';
import HAEQualityOfLifeDone from './components/Views/HAEQualityOfLifeDone';
import Timapantanir from './components/Views/Timapantanir';
import BokaTima from './components/Views/user/BokaTima';

//WebSite routes
import MainWin from './components/Views/MainWin';
import UmOkkur from './components/pages/Um-okkur'
import AdvisoryBoard from './components/pages/AdvisoryBoard';
import BoardOfDirectors from './components/pages/BoardOfDirectors';
import Management from './components/pages/Management';
import PartnerProgram from './components/pages/PartnerProgram';
import Products from './components/pages/Products';
import Login from './components/Views/Login';
import Nyskra from './components/Views/Nyskra';
import Endurstillalykilord  from './components/Views/Endurstilla_lykilord';
import ADHD  from './components/Views/ADHD';
import InnskraFraSMS from './components/Views/Innskrafrasmsi'

function App() {
    return (
        <div className="App">
            <BrowserRouter basename="/">
                <Switch>
                    <Route path="/notandi/">
                        <UserSystem>
                            <PrivateRoute path="/notandi/samskipti" component={Tasks} exact />
                            <PrivateRoute path="/notandi/samskipti/sentmessage/:id" component={ViewSendTasks} exact />
                            <PrivateRoute path="/notandi/samskipti/sent" component={SendMessages} exact />
                            <PrivateRoute path="/notandi/nysamskipti" component={NewTask} exact />
                            <PrivateRoute path="/notandi/samskipti/message/:id" component={ViewTasks} />
                            <PrivateRoute path="/notandi/haexpert/skra" component={AddUrgency} />
                            <PrivateRoute path="/notandi/haexpert/uppfaera/:id" component={EditUrgency} />
                            <PrivateRoute path="/notandi/haexpert/Loka/:id" component={CloseUrgency} />
                            <PrivateRoute path="/notandi/haexpert/" component={HAExpert} exact />
                            <PrivateRoute path="/notandi/hae/lifsgaedi/" component={HAEQualityOfLifeAll} exact />
                            <PrivateRoute path="/notandi/hae/lifsgaedi/new" component={HAEQualityOfLife} exact />
                            <PrivateRoute path="/notandi/hae/lifsgaedi/done" component={HAEQualityOfLifeDone} exact />
                            <PrivateRoute path="/notandi/dashboard" component={Dashboard} />
                            <PrivateRoute path="/notandi/skyrsla" component={Skyrsla} />
                            <PrivateRoute path="/notandi/timapantanir" component={Timapantanir} exact />
                            <PrivateRoute path="/notandi/timapantanir/add" component={BokaTima} exact/>
                            <PrivateRoute path="/notandi/minsida" component={User} />
                        </UserSystem>
                    </Route>

                    <AdminRoute path="/admin/">
                        <AdminSystem>
                            <AdminRoute path="/admin/stjornbord" component={AdminDashboard}  exact />
                            <AdminRoute path="/admin/qol" component={QOL}  exact />
                            <AdminRoute path="/admin/hae" component={HAE}  exact />
                            <AdminRoute path="/admin/hae/:userid" component={HAEUser} />
                            <AdminRoute path="/admin/patient/skra" component={RegisterPatient} exact/>
                            <AdminRoute path="/admin/patients/:id" component={Patient} />
                            <AdminRoute path="/admin/patients" component={Patients} exact />
                            <AdminRoute path="/admin/minsida" component={userprofile} exact />
                            <AdminRoute path="/admin/" component={AdminDashboard} exact/>
                            <AdminRoute path="/admin/staff" component={Staff} exact/>
                            <AdminRoute path="/admin/samskipti" component={StaffSamskipti} exact/>
                            <AdminRoute path="/admin/sendasamskipti" component={NySamskipti} exact/>
                            <AdminRoute path="/admin/samskipti/:id" component={StaffSjaSamskipti} exact/>
                        </AdminSystem>
                    </AdminRoute>

                    <Route path="/">
                        <WebSite>
                            <PublicRoute path="/innskra" component={AdminLogin} exact/>
                            <PublicRoute path="/login" component={Login} />
                            <PublicRoute path="/loginfromsms" component={InnskraFraSMS} />
                            <PublicRoute path="/nyskra" component={Nyskra} />
                            <Route path="/company/advisory-board" component={AdvisoryBoard} />
                            <Route path="/company/board-of-directors" component={BoardOfDirectors} />
                            <Route path="/company/management" component={Management} />
                            <Route path="/company" component={UmOkkur} />
                            <Route path="/partner-program" component={PartnerProgram} />
                            <Route path="/products" component={Products} />
                            <Route path="/endurstilla-lykilord" component={Endurstillalykilord} />
                            <Route path="/adhd" component={ADHD} />
                            <Route path="/" component={MainWin} exact />
                        </WebSite>
                    </Route>
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;