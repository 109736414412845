import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import SystemHeader from './SystemHeader';

import { getToken, removeAdminSession, getSession, getRefreshToken, setRefreshUserSession } from  '../../../Utils/admincommon'

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

function Nysamskipti(props) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [deadline, setDeadline] = useState("");
    const [completed, setCompleted] = useState();
    const [message_type, setMessagetype] = useState(0);
    const [message_to, setMessageto] = useState(0);
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
        try {

            let res = await fetch("https://apiexpeda.pipp.is/admin_messages", {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: title,
                    description: description,
                    completed: 'N',
                    message_type: message_type,
                    message_to: 19,
                    system_message: "2"
                }),
            });

            let resJson = await res.json();
            console.log(resJson);
            if (res.status === 201) {
                setTitle("");
                setDescription("");
                setDeadline("");
                setCompleted("");
                setMessageto(0);
                setMessagetype(0);
                setMessage("Þetta gekk allt eins og í sögu!");
                props.history.push('/admin/samskipti');
        } else {
            setMessage("Ekki tókst að búa til notanda!");
        }
    } catch (err) {
        console.log(err);
    }
};
    return (
        <div>
            <SystemHeader title="Samskipti" />
        
            <main>
                <div className="verkin skoda">
                    <ul className="skilabod">
                        <li><Link to="/notandi/samskipti">Til mín</Link></li>
                        <li><Link to="/notandi/samskipti/sent">Frá mér</Link></li>
                        <li><Link to="/notandi/nysamskipti" className="selected">Senda</Link></li>
                    </ul>
                </div>
                <div className="verkid">
                    <div className="newmessage">
                        <form onSubmit={handleSubmit}>
                        <table className="messagetable">
                            <tr>
                                <td>Efni</td>
                                <td><input type="text" value={title} placeholder="Efni" onChange={(e) => setTitle(e.target.value)} /></td>
                            </tr>
                            
                            <tr>
                                <td>Forgangsstýring:</td>
                                <td>
                                    <select value={message_type} onChange={(e) => setMessagetype(e.target.value)}>
                                        <option value="0">Engin</option>
                                        <option value="1">Mjög áríðandi</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Skilaboð:</td>
                                <td>
                                    <textarea
                                        placeholder="Skilaboð"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td></td>
                                <td><button type="submit" className="newmessage_btn">Senda Skilaboð</button></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td><div className="message">{message ? <p>{message}</p> : null}</div></td>
                            </tr>

                            <input type="hidden" value={deadline} placeholder="Endar þann" onChange={(e) => setDeadline(e.target.value)} />
                            <input type="hidden" value={completed} placeholder="Búið" onChange={(e) => setCompleted(e.target.value)} />
                        </table>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
}

export default Nysamskipti;