import React, { useState } from 'react';
import Topbar from '../templates/Topbar';
import axios from 'axios';
import { getToken } from '../../Utils/Common';
const token = getToken();

const HAEQualityOfLife = () => {
    const [q1, setQ1] = useState("0");
    const [q1Prosenta, setQ1Prosenta] = useState(100);
    const [q2, setQ2] = useState("0");
    const [q2Prosenta, setQ2Prosenta] = useState(100);
    const [q3, setQ3] = useState("0");
    const [q3Prosenta, setQ3Prosenta] = useState(100);
    const [verkirtotal, setVerkirTotal] = useState(0);
    const [verkirtotalprosenta, setVerkirTotalProsenta] = useState(100);
    
    const [q4, setQ4] = useState("0");
    const [q4Prosenta, setQ4Prosenta] = useState(100);
    const [q5, setQ5] = useState("0");
    const [q5Prosenta, setQ5Prosenta] = useState(100);
    const [q6, setQ6] = useState("0");
    const [q6Prosenta, setQ6Prosenta] = useState(100);
    const [virknitotal, setVirkniTotal] = useState(0);
    const [virknitotalprosenta, setVirkniTotalProsenta] = useState(100);

    const [q7, setQ7] = useState("0");
    const [q7Prosenta, setQ7Prosenta] = useState(100);
    const [q8, setQ8] = useState("0");
    const [q8Prosenta, setQ8Prosenta] = useState(100);
    const [andlegtotal, setAndlegTotal] = useState(0);
    const [andlegtotalprosenta, setAndlegTotalProsenta] = useState(100);

    const [q9, setQ9] = useState("0");
    const [q9Prosenta, setQ9Prosenta] = useState(100);
    const [q10, setQ10] = useState("0");
    const [q10Prosenta, setQ10Prosenta] = useState(100);
    const [total, setTotal] = useState(100);
    const [almenntotal, setAlmennTotal] = useState(0);
    const [almenntotalprosenta, setAlmennTotalProsenta] = useState(100);

    const handleChange = event => {
        setQ1(event.target.value);
        let prosentanum = 0;
        let verkirtot = 0;
        let heildarskor = 0;
        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ1Prosenta(prosentanum);
        verkirtot = q3Prosenta + q2Prosenta + prosentanum;
        setVerkirTotalProsenta(verkirtot/3);
        setVerkirTotal(Number(q3) + Number(q2) + Number(event.target.value));

        heildarskor = prosentanum + q2Prosenta + q3Prosenta + q4Prosenta + q5Prosenta + q6Prosenta + q7Prosenta + q8Prosenta + q9Prosenta + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ2 = event => {
        setQ2(event.target.value);
        let prosentanum = 0;
        let verkirtot = 0;
        let heildarskor = 0;

        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ2Prosenta(prosentanum);
        verkirtot = q1Prosenta + q3Prosenta + prosentanum;
        setVerkirTotalProsenta(verkirtot/3);
        setVerkirTotal(Number(q1) + Number(q3) + Number(event.target.value));

        heildarskor = q1Prosenta + prosentanum + q3Prosenta + q4Prosenta + q5Prosenta + q6Prosenta + q7Prosenta + q8Prosenta + q9Prosenta + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ3 = event => {
        setQ3(event.target.value);
        let prosentanum = 0;
        let verkirtot = 0;
        let heildarskor = 0;

        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ3Prosenta(prosentanum);
        verkirtot = q1Prosenta + q2Prosenta + prosentanum;
        setVerkirTotalProsenta(verkirtot/3);
        setVerkirTotal(Number(q1) + Number(q2) + Number(event.target.value));

        heildarskor = q1Prosenta + q2Prosenta + prosentanum + q4Prosenta + q5Prosenta + q6Prosenta + q7Prosenta + q8Prosenta + q9Prosenta + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ4 = event => {
        setQ4(event.target.value);
        let prosentanum = 0;
        let verkirtotal = 0;
        let heildarskor = 0;

        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ4Prosenta(prosentanum);
        verkirtotal = q5Prosenta + q6Prosenta + prosentanum;
        setVirkniTotalProsenta(verkirtotal/3);
        setVirkniTotal(Number(q5) + Number(q6) + Number(event.target.value));

        heildarskor = q1Prosenta + q2Prosenta + q3Prosenta + prosentanum + q5Prosenta + q6Prosenta + q7Prosenta + q8Prosenta + q9Prosenta + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ5 = event => {
        setQ5(event.target.value);
        let prosentanum = 0;
        let verkirtotal = 0;
        let heildarskor = 0;

        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ5Prosenta(prosentanum);
        verkirtotal = q4Prosenta + q6Prosenta + prosentanum;
        setVirkniTotalProsenta(verkirtotal/3);
        setVirkniTotal(Number(q4) + Number(q6) + Number(event.target.value));

        heildarskor = q1Prosenta + q2Prosenta + q3Prosenta + q4Prosenta + prosentanum + q6Prosenta + q7Prosenta + q8Prosenta + q9Prosenta + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ6 = event => {
        setQ6(event.target.value);
        let prosentanum = 0;
        let verkirtotal = 0;
        let heildarskor = 0;

        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ6Prosenta(prosentanum);
        verkirtotal = q5Prosenta + q4Prosenta + prosentanum;
        setVirkniTotalProsenta(verkirtotal/3);
        setVirkniTotal(Number(q5) + Number(q4) + Number(event.target.value));
        heildarskor = q1Prosenta + q2Prosenta + q3Prosenta + q4Prosenta + q5Prosenta + prosentanum + q7Prosenta + q8Prosenta + q9Prosenta + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ7 = event => {
        setQ7(event.target.value);
        let prosentanum = 0;
        let verkirtotal = 0;
        let heildarskor = 0;

        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ7Prosenta(prosentanum);
        verkirtotal = q8Prosenta + prosentanum;
        setAndlegTotalProsenta(verkirtotal/2);
        setAndlegTotal(Number(q8) + Number(event.target.value));

        heildarskor = q1Prosenta + q2Prosenta + q3Prosenta + q4Prosenta + q5Prosenta + q6Prosenta + prosentanum + q8Prosenta + q9Prosenta + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ8 = event => {
        setQ8(event.target.value);
        let prosentanum = 0;
        let verkirtotal = 0;
        let heildarskor = 0;

        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ8Prosenta(prosentanum);
        verkirtotal = q7Prosenta + prosentanum;
        setAndlegTotalProsenta(verkirtotal/2);
        setAndlegTotal(Number(q7) + Number(event.target.value));

        heildarskor = q1Prosenta + q2Prosenta + q3Prosenta + q4Prosenta + q5Prosenta + q6Prosenta + q7Prosenta + prosentanum + q9Prosenta + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ9 = event => {
        setQ9(event.target.value);
        let prosentanum = 0;
        let verkirtotal = 0;
        let heildarskor = 0;
        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ9Prosenta(prosentanum);
        verkirtotal = q10Prosenta + prosentanum;
        setAlmennTotalProsenta(verkirtotal/2);
        setAlmennTotal(Number(q10) + Number(event.target.value));

        heildarskor = q1Prosenta + q2Prosenta + q3Prosenta + q4Prosenta + q5Prosenta + q6Prosenta + q7Prosenta + q8Prosenta + prosentanum + q10Prosenta;
        setTotal(heildarskor/10);
    };

    const handleChangeQ10 = event => {
        setQ10(event.target.value);
        let prosentanum = 0;
        let verkirtotal = 0;
        let heildarskor = 0;
        if (event.target.value === "0") {
            prosentanum = 100;
        } else if (event.target.value === "1") {
            prosentanum = 75;
        } else if (event.target.value === "2") {
            prosentanum = 50;
        } else if (event.target.value === "3") {
            prosentanum = 25;
        } else if (event.target.value === "4") {
            prosentanum = 0;
        }
        setQ10Prosenta(prosentanum);
        verkirtotal = q9Prosenta + prosentanum;
        setAlmennTotalProsenta(verkirtotal/2);
        setAlmennTotal(Number(q9) + Number(event.target.value));

        heildarskor = q1Prosenta + q2Prosenta + q3Prosenta + q4Prosenta + q5Prosenta + q6Prosenta + q7Prosenta + q8Prosenta + q9Prosenta + prosentanum;
        setTotal(heildarskor/10);
    };

    const handleSubmit = event => {
        event.preventDefault();
        const jsonbody = JSON.stringify({
            score: total,
            Q1: q1,
            Q2: q2,
            Q3: q3,
            Q4: q4,
            Q5: q5,
            Q6: q6,
            Q7: q7,
            Q8: q8,
            Q9: q9,
            Q10: q10,
            verkirtotal: verkirtotalprosenta,
            virknitotal: virknitotalprosenta,
            andlegtotal: andlegtotalprosenta,
            almenntotal: almenntotalprosenta,
        });
        const customConfig = {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            }, crossdomain: true
        };

        axios.post('https://apiexpeda.pipp.is/qualityoflife', jsonbody, customConfig )
        .then(res => {
                if (res.data.statusCode === 201) {
                    window.location = "/notandi/hae/lifsgaedi/done";
                } else {
                    console.log("Villa: " + res.data.statusCode)
                }
            }
        )
        .catch(error => {
			const response = error.response;
            console.log("Villa: " + response);
        })
        /*
        try {
            //score,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,Q10,verkirtotal,virknitotal,andlegtotal,almenntotal
            let res = fetch("https://apiexpeda.pipp.is/qualityoflife", {
                method: "POST",
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                },
                crossdomain: true,
                body: JSON.stringify({
                    score: total,
                    Q1: q1,
                    Q2: q2,
                    Q3: q3,
                    Q4: q4,
                    Q5: q5,
                    Q6: q6,
                    Q7: q7,
                    Q8: q8,
                    Q9: q9,
                    Q10: q10,
                    verkirtotal: verkirtotalprosenta,
                    virknitotal: virknitotalprosenta,
                    andlegtotal: andlegtotalprosenta,
                    almenntotal: almenntotalprosenta,
                }),
            });
            console.log(res);
            if (res.status === 201) {
                this.setMessage("");
            } else {
                this.setMessage("");
            }
        } catch (err) {
            console.log("Done")
            //window.location = "/notandi/hae/lifsgaedi/done";
        }
        */
    };

    return (
        <div>
            <Topbar title="menu.lifsgaedi" heiti=""/>
            <main>
                <form onSubmit={handleSubmit}>
                <h3 className="lifsgaedautskyring">Merkja við þann valmöguleika sem lýsir því best hversu ánægð/ur eða óánægð/ur þú ert með líf þitt á hverju sviði</h3>
                <div className="valspurningar">
                <div className="lifsgaeda_flokkur">
                    <h2>Verkir</h2>
                    <h3 className="questions">Verkir í stoðkerfi</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={q1 === "0"} id="verkir_stodkerfi_0" value="0" onChange={handleChange} />
						<label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Engir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={q1 === "1"} id="verkir_stodkerfi_1" value="1" onChange={handleChange} />
						<label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Vægir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={q1 === "2"} id="verkir_stodkerfi_2" value="2" onChange={handleChange} />
						<label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkrir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={q1 === "3"} id="verkir_stodkerfi_3" value="3" onChange={handleChange} />
						<label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Miklir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="verkir_stodkerfi" checked={q1 === "4"} id="verkir_stodkerfi_4" value="4" onChange={handleChange} />
						<label className="for-checkbox-tools" htmlFor="verkir_stodkerfi_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Verstu mögulegu<br/> verkir</div>
						</label>
					</div>

                    <h3 className="questions">Kviðverkir</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="kvidverkir" checked={q2 === "0"} id="kvidverkir_0" value="0" onChange={handleChangeQ2} />
						<label className="for-checkbox-tools" htmlFor="kvidverkir_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Engir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="kvidverkir" checked={q2 === "1"} id="kvidverkir_1" value="1" onChange={handleChangeQ2} />
						<label className="for-checkbox-tools" htmlFor="kvidverkir_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Vægir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="kvidverkir" checked={q2 === "2"} id="kvidverkir_2" value="2" onChange={handleChangeQ2} />
						<label className="for-checkbox-tools" htmlFor="kvidverkir_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkrir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="kvidverkir" checked={q2 === "3"} id="kvidverkir_3" value="3" onChange={handleChangeQ2} />
						<label className="for-checkbox-tools" htmlFor="kvidverkir_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Miklir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="kvidverkir" checked={q2 === "4"} id="kvidverkir_4" value="4" onChange={handleChangeQ2} />
						<label className="for-checkbox-tools" htmlFor="kvidverkir_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Verstu mögulegu<br/> verkir</div>
						</label>
					</div>
                    <h3 className="questions">Höfuðverkir</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="hofudverkir" checked={q3 === "0"} id="hofudverkir_0" value="0" onChange={handleChangeQ3} />
						<label className="for-checkbox-tools" htmlFor="hofudverkir_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Engir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="hofudverkir" checked={q3 === "1"} id="hofudverkir_1" value="1" onChange={handleChangeQ3} />
						<label className="for-checkbox-tools" htmlFor="hofudverkir_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Vægir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="hofudverkir" checked={q3 === "2"} id="hofudverkir_2" value="2" onChange={handleChangeQ3} />
						<label className="for-checkbox-tools" htmlFor="hofudverkir_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkrir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="hofudverkir" checked={q3 === "3"} id="hofudverkir_3" value="3" onChange={handleChangeQ3} />
						<label className="for-checkbox-tools" htmlFor="hofudverkir_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Miklir<br/> verkir</div>
						</label><input className="checkbox-tools" type="radio" name="hofudverkir" checked={q3 === "4"} id="hofudverkir_4" value="4" onChange={handleChangeQ3} />
						<label className="for-checkbox-tools" htmlFor="hofudverkir_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Verstu mögulegu<br/> verkir</div>
						</label>
					</div>
                </div>

                <div className="lifsgaeda_flokkur">
                    <h2 className="cat2">Líkamleg virkni</h2>
                    <h3 className="questions">Þreyta (orkuleysi, magnleysi)</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="threyta" checked={q4 === "0"} id="likamleg_threyta_0" value="0" onChange={handleChangeQ4} />
						<label className="for-checkbox-tools" htmlFor="likamleg_threyta_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Engin<br/> þreyta</div>
						</label><input className="checkbox-tools" type="radio" name="threyta" checked={q4 === "1"} id="likamleg_threyta_1" value="1" onChange={handleChangeQ4} />
						<label className="for-checkbox-tools" htmlFor="likamleg_threyta_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Væg<br/> þreyta</div>
						</label><input className="checkbox-tools" type="radio" name="threyta" checked={q4 === "2"} id="likamleg_threyta_2" value="2" onChange={handleChangeQ4} />
						<label className="for-checkbox-tools" htmlFor="likamleg_threyta_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkur<br/> þreyta</div>
						</label><input className="checkbox-tools" type="radio" name="threyta" checked={q4 === "3"} id="likamleg_threyta_3" value="3" onChange={handleChangeQ4} />
						<label className="for-checkbox-tools" htmlFor="likamleg_threyta_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Mikil<br/> þreyta</div>
						</label><input className="checkbox-tools" type="radio" name="threyta" checked={q4 === "4"} id="likamleg_threyta_4" value="4" onChange={handleChangeQ4} />
						<label className="for-checkbox-tools" htmlFor="likamleg_threyta_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Versta mögulega<br/> þreyta</div>
						</label>
					</div>
                    
                    <h3 className="questions">Mæði</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="maedi" checked={q5 === "0"} id="likamleg_maedi_0" value="0" onChange={handleChangeQ5} />
						<label className="for-checkbox-tools" htmlFor="likamleg_maedi_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Engin<br/> mæði</div>
						</label><input className="checkbox-tools" type="radio" name="maedi" checked={q5 === "1"} id="likamleg_maedi_1" value="1" onChange={handleChangeQ5} />
						<label className="for-checkbox-tools" htmlFor="likamleg_maedi_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Væg<br/> mæði</div>
						</label><input className="checkbox-tools" type="radio" name="maedi" checked={q5 === "2"} id="likamleg_maedi_2" value="2" onChange={handleChangeQ5} />
						<label className="for-checkbox-tools" htmlFor="likamleg_maedi_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkur<br/> mæði</div>
						</label><input className="checkbox-tools" type="radio" name="maedi" checked={q5 === "3"} id="likamleg_maedi_3" value="3" onChange={handleChangeQ5} />
						<label className="for-checkbox-tools" htmlFor="likamleg_maedi_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Mikil<br/> mæði</div>
						</label><input className="checkbox-tools" type="radio" name="maedi" checked={q5 === "4"} id="likamleg_maedi_4" value="4" onChange={handleChangeQ5} />
						<label className="for-checkbox-tools" htmlFor="likamleg_maedi_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Versta mögulega<br/> mæði</div>
						</label>
					</div>
                    
                    <h3 className="questions">Geta til almennra athafna</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="geta" checked={q6 === "0"} id="likamleg_geta_0" value="0" onChange={handleChangeQ6} />
						<label className="for-checkbox-tools" htmlFor="likamleg_geta_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Full<br/> geta</div>
						</label><input className="checkbox-tools" type="radio" name="geta" checked={q6 === "1"} id="likamleg_geta_1" value="1" onChange={handleChangeQ6} />
						<label className="for-checkbox-tools" htmlFor="likamleg_geta_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Mikil<br/> geta</div>
						</label><input className="checkbox-tools" type="radio" name="geta" checked={q6 === "2"} id="likamleg_geta_2" value="2" onChange={handleChangeQ6} />
						<label className="for-checkbox-tools" htmlFor="likamleg_geta_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkur<br/> geta</div>
						</label><input className="checkbox-tools" type="radio" name="geta" checked={q6 === "3"} id="likamleg_geta_3" value="3" onChange={handleChangeQ6} />
						<label className="for-checkbox-tools" htmlFor="likamleg_geta_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Væg<br/> geta</div>
						</label><input className="checkbox-tools" type="radio" name="geta" checked={q6 === "4"} id="likamleg_geta_4" value="4" onChange={handleChangeQ6} />
						<label className="for-checkbox-tools" htmlFor="likamleg_geta_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Engin<br/> geta</div>
						</label>
					</div>
                </div>

                <div className="lifsgaeda_flokkur">
                    <h2 className="cat3">Andleg líðan</h2>
                    <h3 className="questions">Kvíði</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="kvidi" checked={q7 === "0"} id="andleg_kvidi_0" value="0" onChange={handleChangeQ7} />
						<label className="for-checkbox-tools" htmlFor="andleg_kvidi_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Enginn<br/> kvíði</div>
						</label><input className="checkbox-tools" type="radio" name="kvidi" checked={q7 === "1"} id="andleg_kvidi_1" value="1" onChange={handleChangeQ7} />
						<label className="for-checkbox-tools" htmlFor="andleg_kvidi_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Vægur<br/> kvíði</div>
						</label><input className="checkbox-tools" type="radio" name="kvidi" checked={q7 === "2"} id="andleg_kvidi_2" value="2" onChange={handleChangeQ7} />
						<label className="for-checkbox-tools" htmlFor="andleg_kvidi_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkur<br/> kvíði</div>
						</label><input className="checkbox-tools" type="radio" name="kvidi" checked={q7 === "3"} id="andleg_kvidi_3" value="3" onChange={handleChangeQ7} />
						<label className="for-checkbox-tools" htmlFor="andleg_kvidi_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Mikill<br/> kvíði</div>
						</label><input className="checkbox-tools" type="radio" name="kvidi" checked={q7 === "4"} id="andleg_kvidi_4" value="4" onChange={handleChangeQ7} />
						<label className="for-checkbox-tools" htmlFor="andleg_kvidi_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Versti mögulegi<br/> kvíði</div>
						</label>
					</div>
                    <h3 className="questions">Þunglyndi</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="thunglyndi" checked={q8 === "0"} id="andleg_thunglyndi_0" value="0" onChange={handleChangeQ8} />
						<label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Ekkert<br/> þunglyndi</div>
						</label><input className="checkbox-tools" type="radio" name="thunglyndi" checked={q8 === "1"} id="andleg_thunglyndi_1" value="1" onChange={handleChangeQ8} />
						<label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Vægt<br/> þunglyndi</div>
						</label><input className="checkbox-tools" type="radio" name="thunglyndi" checked={q8 === "2"} id="andleg_thunglyndi_2" value="2" onChange={handleChangeQ8} />
						<label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Þónokkurt<br/> þunglyndi</div>
						</label><input className="checkbox-tools" type="radio" name="thunglyndi" checked={q8 === "3"} id="andleg_thunglyndi_3" value="3" onChange={handleChangeQ8} />
						<label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Mikið<br/> þunglyndi</div>
						</label><input className="checkbox-tools" type="radio" name="thunglyndi" checked={q8 === "4"} id="andleg_thunglyndi_4" value="4" onChange={handleChangeQ8} />
						<label className="for-checkbox-tools" htmlFor="andleg_thunglyndi_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Versta mögulega<br/> þunglyndi</div>
						</label>
					</div>
                </div>

                <div className="lifsgaeda_flokkur">
                    <h2 className="cat4">Almenn líðan</h2>

                    <h3 className="questions">Almenn líðan </h3>

                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="almennlidan" checked={q9 === "0"} id="almenn_lidan_0" value="0" onChange={handleChangeQ9} />
						<label className="for-checkbox-tools" htmlFor="almenn_lidan_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Góð<br/> líðan</div>
						</label><input className="checkbox-tools" type="radio" name="almennlidan" checked={q9 === "1"} id="almenn_lidan_1" value="1" onChange={handleChangeQ9} />
						<label className="for-checkbox-tools" htmlFor="almenn_lidan_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Ágæt<br/> líðan</div>
						</label><input className="checkbox-tools" type="radio" name="almennlidan" checked={q9 === "2"} id="almenn_lidan_2" value="2" onChange={handleChangeQ9} />
						<label className="for-checkbox-tools" htmlFor="almenn_lidan_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Sæmileg<br/> líðan</div>
						</label><input className="checkbox-tools" type="radio" name="almennlidan" checked={q9 === "3"} id="almenn_lidan_3" value="3" onChange={handleChangeQ9} />
						<label className="for-checkbox-tools" htmlFor="almenn_lidan_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Slæm<br/> líðan</div>
						</label><input className="checkbox-tools" type="radio" name="almennlidan" checked={q9 === "4"} id="almenn_lidan_4" value="4" onChange={handleChangeQ9} />
						<label className="for-checkbox-tools" htmlFor="almenn_lidan_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Versta mögulega<br/> líðan</div>
						</label>
					</div>

                    <h3 className="questions">Svefngæði</h3>
                    <div className="spurningar_checkboxes">
						<input className="checkbox-tools" type="radio" name="svefngaedi" checked={q10 === "0"} id="almenn_svefngaedi_0" value="0" onChange={handleChangeQ10} />
						<label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_0">
                            <i className="fa-regular fa-face-smile"></i> <div>Góður<br/> svefn</div>
						</label><input className="checkbox-tools" type="radio" name="svefngaedi" checked={q10 === "1"} id="almenn_svefngaedi_1" value="1" onChange={handleChangeQ10} />
						<label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_1">
                            <i className="fa-regular fa-face-expressionless"></i> <div>Ágætur<br/> svefn</div>
						</label><input className="checkbox-tools" type="radio" name="svefngaedi" checked={q10 === "2"} id="almenn_svefngaedi_2" value="2" onChange={handleChangeQ10} />
						<label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_2">
                            <i className="fa-regular fa-face-frown-slight"></i> <div>Sæmilegur<br/> svefn</div>
						</label><input className="checkbox-tools" type="radio" name="svefngaedi" checked={q10 === "3"} id="almenn_svefngaedi_3" value="3" onChange={handleChangeQ10} />
						<label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_3">
                            <i className="fa-regular fa-face-anguished"></i> <div>Slæmur<br/> svefn</div>
						</label><input className="checkbox-tools" type="radio" name="svefngaedi" checked={q10 === "4"} id="almenn_svefngaedi_4" value="4" onChange={handleChangeQ10} />
						<label className="for-checkbox-tools" htmlFor="almenn_svefngaedi_4">
                            <i className="fa-regular fa-face-confounded"></i> <div>Versti mögulegi<br/> svefn</div>
						</label>
					</div>
                </div>
                </div>
                <div className="nyttprof">
                    <input type="submit" className="vistaprof" value="Vista próf" /><br/><br/>
                </div><div className="svorvidspurnigum">
                    <h3 className="heildarskor">Heildarskor {Math.round(total)}%</h3>
                </div>
                </form>
            </main>
        </div>
    );
}

export default HAEQualityOfLife;