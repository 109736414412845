import React, { Component } from 'react'
import axios from 'axios';
import UserGrafByID from './user/components/UserGrafByID';
import Topbar from '../templates/Topbar';

import {getToken} from '../../Utils/Common';
const token = getToken();

export class Skyrsla extends Component {
    state = { posts: [], isLoaded: false, userid: null };
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/user',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ userid: res.data.data.userid, isLoaded: true }))
    }

    render() {
        const { isLoaded, userid} = this.state;
        if (isLoaded) {
            return (
                <div>
                    <Topbar title="menu.skyrsla" heiti="" />
                    <main>
                        <div className="yfirlit">
                            <UserGrafByID userid={userid}/>
                        </div>
                    </main>
                </div>
            )
        } else {
            return (
                <div>
                    <Topbar title="menu.skyrsla" heiti="" />
                    <main>
                        <div className="yfirlit">
                           
                        </div>
                    </main>
                </div>
            )
        }
    }
}

export default Skyrsla;