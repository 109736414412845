import React, { useState } from 'react';
import Topbar from '../../templates/Topbar';
import { getToken } from '../../../Utils/Common';
import DateTimePicker from 'react-datetime-picker';

import '../AddUrgency.css';

import moment, { now } from "moment";
import 'moment/locale/is';

const token = getToken();

function Bokatima(props) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [deadline, setDeadline] = useState("");
    const [completed, setCompleted] = useState();
    const [message, setMessage] = useState("");

    let handleSubmit = async (e) => {
        e.preventDefault();
    };

    return (
        <main>
            <Topbar title="home.appointments" heiti="" />
            <div className="innskraningssida">
                <h2 className="nyskra">Bóka tíma</h2>
                <form onSubmit={handleSubmit}>
                    <h3 className="page-title">Dagsetning / tímasetning</h3>
                    <input type="text" value="Mánudaginn 31 Okt 2022" placeholder="Dagur" />
                    <input type="text" value="16:00" placeholder="Tími" />

                    <h3 className="page-title">Læknir</h3>
                    <select>
                        <option value="4">Björn Rúnar Lúðvíksson</option>
                    </select>

                    <h3 className="page-title">Tegund</h3>
                    <select>
                        <option value="1">Símtal</option>
                        <option value="2">Myndsímtal</option>
                        <option value="3">Komutími</option>
                    </select>
                    <textarea
                        placeholder="Lýsing"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <input type="hidden" value={deadline} placeholder="Endar þann" onChange={(e) => setDeadline(e.target.value)} />
                    <input type="hidden" value={completed} placeholder="Búið" onChange={(e) => setCompleted(e.target.value)} />
                    <button type="submit" className="buatilnotenda">Bóka!</button>
                    <div className="message">{message ? <p>{message}</p> : null}</div>
                </form>
            </div>
        </main>
    );
}

export default Bokatima;