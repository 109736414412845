import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import { withTranslation} from 'react-i18next';

import { removeUserSession } from '../../Utils/Common';

import './Sidebar.css';

export class Sidebar extends Component {
    render() {
        const {t} = this.props;

        let handleLogout = async (e) => {
            e.preventDefault();
            removeUserSession();
        }

        return (
            <div className="sidebar">
                <h1><NavLink to="/notandi/dashboard" activeClassName="selected">Fruma</NavLink></h1>
                <h4 >Almennt</h4>
                <ul>
                    <li><NavLink to="/notandi/dashboard" activeClassName="selected"><i className="fa-regular fa-grid-2"></i> {t('menu.yfirlit')}</NavLink></li>
                    <li><NavLink to="/notandi/samskipti" activeClassName="selected"><i className="fa-regular fa-inboxes"></i> {t('menu.samskipti')}</NavLink></li>
                    <li><NavLink to="/notandi/skyrsla" activeClassName="selected"><i className="fa-regular fa-file-chart-column"></i> {t('menu.skyrsla')}</NavLink></li>
                    <li><NavLink to="/notandi/timapantanir" activeClassName="selected"><i className="fa-solid fa-calendar-check"></i> {t('home.appointments')}</NavLink></li>
                </ul>
                <h4>Tól</h4>
                <ul>
                    <li><NavLink to="/notandi/HAExpert" activeClassName="selected"><i className="fa-regular fa-monitor-waveform"></i> {t('menu.haexpert')}</NavLink></li>
                    <li><NavLink to="/notandi/hae/lifsgaedi/" activeClassName="selected"><i className="fa-regular fa-folder-medical"></i> {t('menu.lifsgaedi')}</NavLink></li>
                </ul>
                <ul className="litir">
                    <li><span></span> #ff455f</li>
                    <li><span className="gulur"></span> #f8d483</li>
                    <li><span className="ljosblar"></span> #2ac2d3</li>
                    <li><span className="blar"></span> #3b72a1</li>
                    <li><span className="svartur"></span> #221f20</li>
                    <li><span className="ljosgraenn"></span> #1effbc</li>
                    <li><span className="fjolublar"></span> #6149CD</li>
                    
                </ul>
                <ul className="actionBtn">
                    <div className="logingout">
                        <button onClick={handleLogout}><i className="fa-solid fa-power-off"></i> Útskrá</button>
                    </div>
                </ul>
            </div>
        )
    }
}

export default withTranslation()(Sidebar);