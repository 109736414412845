import React, { Component } from 'react'
import axios from 'axios';
import Topbar from '../templates/Topbar';
import ReactPaginate from 'react-paginate';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import { getToken, getSession, getRefreshToken, removeUserSession, setRefreshUserSession } from '../../Utils/Common';

import moment from "moment";
import 'moment/locale/is';

const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();

export class HAExpert extends Component {

    state = { posts: [], isLoaded: false, perpage: 0, rowscount: 0, open: 0 }
    componentDidMount () {
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        axios.get('https://apiexpeda.pipp.is/urgency/page/0', {
            param: {
                per_page: 2
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true, perpage: res.data.data.per_page, rowscount: res.data.data.rows_all, open: res.data.data.Open }))
        .catch(error => {
			const response = error.response;
            /*
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('https://apiexpeda.pipp.is/sessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
            */
        })
    }

    handlePageClick = (event) => {

        let valid = event.selected ;
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        axios.get('https://apiexpeda.pipp.is/urgency/page/'+valid, {
            param: {
                per_page: 2
            },
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true, perpage: res.data.data.per_page, rowscount: res.data.data.rows_all }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('https://apiexpeda.pipp.is/sessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeUserSession();
                window.location.reload();
            }
        })
    };

    render() {

        const { t } = this.props;
        const { posts, isLoaded, rowscount ,perpage, open } = this.state;
        console.log(posts);

        let pages = rowscount / perpage + 1;
        if (isLoaded) {
            return (
                <div>
                    <Topbar title="menu.haexpert" heiti=""/>
                    <main>
                        <h1>Bólguköst <span>({rowscount})</span> <Link to="/notandi/haexpert/skra" className="addUrgency"><i className="fa-solid fa-square-plus"></i> Skrá bólgukast</Link></h1>
                        <ul className='filter hidethis'>
                            <li><button>Sýna opin köst ({open})</button></li>
                        </ul>
                        <table className='qualityoflife'>
                            <thead>
                                <tr>
                                    <th>Skráð</th>
                                    <th>Kast</th>
                                    <th>Ráðgjöf</th>
                                    <th>Ástæða</th>
                                    <th className='tr'>Staða</th>
                                </tr>
                            </thead>
                            <tbody>
                                {posts.map(post =>
                                    <tr key={post.id}>
                                        <td><Link to={"/notandi/haexpert/uppfaera/"+post.id} className="skodakast">{moment(post.registered).format('Do MMM YYYY')}</Link></td>
                                        <td><Link to={"/notandi/haexpert/uppfaera/"+post.id} className={"kast_"+post.hex}>{post.ended}</Link></td>
                                        <td>{post.reaction}</td>
                                        <td>{post.cause}</td>
                                        <td className='tr'>{athugaStodu(post.status, post.id)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className='pagination'>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={this.handlePageClick}
                                pageRangeDisplayed={10}
                                pageCount={Math.round(pages)}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </main>
                </div>
            )
        } else {
            return (
                <div>
                    <Topbar title={t('menu.haexpert')}/>
                    <main>
                        <h1>HAExpert <Link to="/haexpert/skra" className="addUrgency">Skrá Alvarlegt bráðkasts</Link></h1>
                        <div className="urgencies">
                            <div className="urgency">
                                Hleð inn...
                            </div>
                        </div>
                    </main>
                </div>
            )
        }
    }
}

function athugaStodu(p,id) {
    if (p === 0) {
        let thisIsMyCopy = "<a href='/notandi/haexpert/Loka/"+id+"'>Loka kasti</a>"
        return <div className='opid' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    } else {
        let thisIsMyCopy = "<span>Kasti Lokið</span>";
        return <div className='lokad' dangerouslySetInnerHTML={{__html: thisIsMyCopy}}></div>
    }
}

export default withTranslation()(HAExpert);