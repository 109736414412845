import React, { useState } from 'react';
import {useParams} from 'react-router-dom';
import SystemHeader from './SystemHeader';
import UserGraf from './components/UserGraf';
import PatientForm from './components/PatientForm';

document.body.classList.add("admin");

const Patient = () => {
    const params = useParams();

    return (
        <div>
            <SystemHeader title="Sjúklingur" />
            <div className="yfirlit">
                <div className='sjuklingur_upplysingar'>
                    <PatientForm userid={params.id}/>
                </div>
                <div className='sjuklingur_notkun'>
                    <UserGraf userid={params.id}/>
                </div>
            </div>
        </div>
    )
}

export default Patient