import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

import { getToken } from '../../../../Utils/Common';
const token = getToken();

export class Heildarskor extends Component {

    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {

        axios.get('https://apiexpeda.pipp.is/qualityoflife', {
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        })
        .then(res => this.setState({ posts: res.data.data.qol, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        const { t } = this.props;
        if (isLoaded) {
            if (posts[0]) {
                let verkirtotal = 100 - posts[0].verkirtotal;
                let virknitotal = posts[0].virknitotal;
                let andlegtotal = posts[0].andlegtotal;
                let almenntotal = posts[0].almenntotal;
                let heildarskor = posts[0].score;

                let verkirtotalLitur = "litur_0";
                let virknitotalLitur = "litur_4";
                let andlegtotalLitur = "litur_4";
                let almenntotalLitur = "litur_4";

                if (verkirtotal <= 25 && verkirtotal > 0) {
                    verkirtotalLitur = "litur_0";
                } else if (verkirtotal > 25 && verkirtotal <= 50) {
                    verkirtotalLitur = "litur_1";
                } else if (verkirtotal > 50 && verkirtotal <= 75) {
                    verkirtotalLitur = "litur_2";
                } else if (verkirtotal > 75 && verkirtotal <= 99) {
                    verkirtotalLitur = "litur_3";
                } else if (verkirtotal >= 100) {
                    verkirtotalLitur = "litur_4";
                }

                if (virknitotal <= 25 && virknitotal > 0) {
                    virknitotalLitur = "litur_4";
                } else if (virknitotal > 25 && virknitotal <= 50) {
                    virknitotalLitur = "litur_3";
                } else if (virknitotal > 50 && virknitotal <= 75) {
                    virknitotalLitur = "litur_2";
                } else if (virknitotal > 75 && virknitotal <= 99) {
                    virknitotalLitur = "litur_1";
                } else if (virknitotal >= 100) {
                    virknitotalLitur = "litur_0";
                }

                if (andlegtotal <= 25 && andlegtotal > 0) {
                    andlegtotalLitur = "litur_4";
                } else if (andlegtotal > 25 && andlegtotal <= 50) {
                    andlegtotalLitur = "litur_3";
                } else if (andlegtotal > 50 && andlegtotal <= 75) {
                    andlegtotalLitur = "litur_2";
                } else if (andlegtotal > 75 && andlegtotal <= 99) {
                    andlegtotalLitur = "litur_1";
                } else if (andlegtotal >= 100) {
                    andlegtotalLitur = "litur_0";
                }

                if (almenntotal <= 25 && almenntotal > 0) {
                    almenntotalLitur = "litur_4";
                } else if (almenntotal > 25 && almenntotal <= 50) {
                    almenntotalLitur = "litur_3";
                } else if (almenntotal > 50 && almenntotal <= 75) {
                    almenntotalLitur = "litur_2";
                } else if (almenntotal > 75 && almenntotal <= 99) {
                    almenntotalLitur = "litur_1";
                } else if (almenntotal >= 100) {
                    almenntotalLitur = "litur_0";
                }

                return (
                    <div className="heildarskor" key="heildarskor">
                        <h3 className='section_heading'>{t('menu.lifsgaedi')} <Link to="/notandi/hae/lifsgaedi/" className='fr'><i className="fa-solid fa-ellipsis"></i></Link></h3>
                        <div className="lifsgaedi" key="rings">
                            <div className="lifsgaedi_heildarskor" key="1">
                                <h3 className="heildarskor">Heildarskor {heildarskor}% </h3>
                                <p className="heildarskor">Próf var tekið þann {moment(posts[0].createdon).format('Do MMM YYYY')} </p>
                            </div>
                            <div className={"lifsgaedi_verkir " + verkirtotalLitur}  key="2">
                                <svg className="ActivityRings" viewBox="0 0 37 37">
                                    <g className="ring ring1">
                                        <circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="background" />
                                        <circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={ verkirtotal + ",100"} />
                                    </g>
                                </svg><div className="heild">
                                    Verkir  <strong>{verkirtotal}%</strong>
                                </div>
                            </div>
                            <div className={"lifsgaedi_likamlegvirkni " + virknitotalLitur} key="3">
                                <svg className="ActivityRings" viewBox="0 0 37 37">
                                    <g className="ring ring1">
                                        <circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="background" />
                                        <circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={virknitotal + ",100"} />
                                    </g>
                                </svg><div className="heild">
                                    Líkamleg virkni  <strong>{virknitotal}%</strong>
                                </div>
                            </div>
                            <div className={"lifsgaedi_andleglidan " + andlegtotalLitur} key="4">
                                <svg className="ActivityRings" viewBox="0 0 37 37">
                                    <g className="ring ring1">
                                        <circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="background" />
                                        <circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={andlegtotal + ",100"} />
                                    </g>
                                </svg><div className="heild">
                                    Andleg líðan  <strong>{andlegtotal}%</strong>
                                </div>
                            </div>
                            <div className={"lifsgaedi_almennlidan " + almenntotalLitur} key="5">
                                <svg className="ActivityRings" viewBox="0 0 37 37">
                                    <g className="ring ring1">
                                        <circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="background" />
                                        <circle strokeWidth="5" r="15.915" cx="50%" cy="50%" className="completed" strokeDasharray={almenntotal + ",100"} />
                                    </g>
                                </svg><div className="heild">
                                    Almenn líðan <strong>{almenntotal}%</strong>
                                </div>
                            </div>
                            <div className='lifsgaedi_nytt'>
                                <Link to="/notandi/hae/lifsgaedi/">Nýtt</Link>
                            </div>
                        </div>
                    </div>
                )
            } else {
                 return (
                    <div>ekkert próf</div>
                 )
            }
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

export default withTranslation()(Heildarskor);