import React, { Component } from 'react'
import axios from 'axios';
import { getToken } from  '../../../../Utils/admincommon'

const token = getToken();

export class UserDropdown extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/getusers',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.user, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        function handleChange(value) {
            console.log(value)
            window.location.href = value;
        }

        const { posts } = this.state;
        return (
            <select onChange={event => handleChange(event.target.value)}>
                <option value="/admin/hae">Allir sjúklingar</option>
                {posts.map(post =>
                    <option value={"/admin/hae/" + post.id} key={post.id}>{post.name}</option>
                )}
            </select>
        )
    }
}

export default UserDropdown;