function BoardOfDirectors() {
    return (
        <main>
            <div className="main">
                <h3 className="yfirfyrirsogn">Expeda</h3>
                <h2 className="fyrirsogn">Board of directors</h2>
                <p><strong>Mr. Gunnar Armannsson</strong></p>
                <p>Mr. Armannsson serves as the board chairman of Expeda.  He has a degree in law from the University of Iceland. Mr. Armannsson also holds an MBA degree from the same University. Mr. Armannson held several posts at the Directorate of Customs in the years 1993 - 2002. Mr. Armannson also served as the CEO for the Medical Association of Iceland for seven years from 2002 to 2009. Since 2009 he has been the CEO of PrimaCare ehf., a private healthcare project focusing on healthcare tourism in Iceland.  Mr. Armannson has also been an in-house counselor for VHE ehf., since 2012.</p>
                <p><strong>Mr.  David A. Gunnarsson</strong></p>
                <p>Mr. Gunnarsson has a degree in Engineering from the Royal Institute of Technology (KTH) in Sweden and both a degree in Economics and Public Administration from Stockholm University. Mr. Gunnarsson served as the Director General, National University hospital in Iceland from 1980  – 1995.  From 1995 - 2007 he served as the Permanent Secretary for the Ministry of Health and Social Security in Iceland.  Mr. Gunnarsson has acted as a chairperson in various organizations e.g. The Chairman of the Executive Board of The World Health Organization.  He was a candidate for the post of Director General of WHO and among other things a coauthor of literature in Health Economics:  "Health Economics as a tool for Leaders" and "Learning to Live with Health Economics". Both publications are available on the internet</p>
                <p><strong>Mr. Unnar Hjaltason</strong></p>
                <p>Mr. Hjaltason is the managing director and owner of VHE ehf, a large engineering and manufacturing firm in Iceland, focusing on construction and High tech services for the global aluminum industry.</p>
            </div>
        </main>
    )
}

export default BoardOfDirectors;