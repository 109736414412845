import React, { Component } from 'react'
import axios from 'axios';
import Topbar from '../templates/Topbar';
import { withTranslation } from 'react-i18next';
import { getToken } from '../../Utils/Common';
import { Link } from 'react-router-dom';

import moment from "moment";
import 'moment/locale/is';

const token = getToken();

export class SendMessages extends Component {
    
    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/messages/send',{
            headers: { 
                'Authorization': token
            }
        })
        .then(res => this.setState({ posts: res.data.data.tasks, isLoaded: true }))
    }

    render() {
        const { t } = this.props;
        const { posts, isLoaded} = this.state;
        if (isLoaded) {
            if (posts.length > 0) {
                let mydescription = posts[0].description.replace(/(?:\r\n|\r|\n)/g, '<br />')

                return (
                    <div>
                        <Topbar title="menu.samskipti" heiti="" />
                        <main>
                            <div className="verkin skoda">
                                <ul className="skilabod">
                                    <li><Link to="/notandi/samskipti">Til mín</Link></li>
                                    <li><Link to="/notandi/samskipti/sent" className="selected">Frá mér</Link></li>
                                    <li><Link to="/notandi/nysamskipti">Senda</Link></li>
                                </ul>

                                {posts.map(post =>
                                    <div className={"item " + post.read} key={post.id}>
                                        <div className="mynd"></div>
                                        <div className="upplysingar">
                                            <h3><Link to={"/notandi/samskipti/sentmessage/" + post.id}>{post.title}</Link></h3>
                                            <p>{moment(post.deadline).format('Do MMM YYYY')}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="verkid">
                                <h3>{posts[0].title}</h3>
                                <h4>{moment(posts[0].deadline).format('Do MMM YYYY H:mm:ss')}</h4>
                                <div className='skilabodin' dangerouslySetInnerHTML={{ __html: mydescription }} />
                            </div>
                        </main>
                    </div>
                )
            } else {
                return (
                    <div>
                        <Topbar title="menu.samskipti" heiti="" />
                        <main>
                            <div className="verkin skoda">
                                <ul className="skilabod">
                                    <li><Link to="/notandi/samskipti">Til mín</Link></li>
                                    <li><Link to="/notandi/samskipti/sent" className="selected">Frá mér</Link></li>
                                    <li><Link to="/notandi/nysamskipti">Senda</Link></li>
                                </ul>

                                {posts.map(post =>
                                    <div className={"item " + post.read} key={post.id}>
                                        <div className="mynd"></div>
                                        <div className="upplysingar">
                                            <h3><Link to={"/notandi/samskipti/sentmessage/" + post.id}>{post.title}</Link></h3>
                                            <p>{moment(post.deadline).format('Do MMM YYYY')}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="verkid">Þú hefur ekki sent nein skilaboð</div>
                        </main>
                    </div>
                )
            }
        } else {
            return (
                <div>
                    <Topbar title={t('menu.samskipti')}/>
                    <main>
                        <h1 className="page-title">Öll skilaboð <a href="/notandi/nysamskipti">Senda skilaboð</a></h1>
                    </main>
                </div>
            )
        }
    }
}

export default withTranslation()(SendMessages);