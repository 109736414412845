import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import './Sidebar.css';

function MobileHeader() {
    const [isShown, setIsShown] = useState("closed");

    let handleChange = async () => {
        if (isShown === "closed") {
            setIsShown("open");
        } else {
            setIsShown("closed");
        }
    }

    return (
        <div>
            <div id="hamborgari" onClick={handle => {handleChange();}} className={isShown}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div id="menu" className={isShown}>
                <MobileMenu/>
            </div>
            <div className="mobile-header"><h1 className="logo"><NavLink to="/notandi/dashboard" activeClassName="selected"><span></span></NavLink></h1></div>
        </div>
        )

}

export default MobileHeader;
