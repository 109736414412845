import React, { Component } from 'react'
import axios from 'axios';
import { withTranslation} from 'react-i18next';

import { getToken } from '../../../../Utils/Common';
const token = getToken();

export class NaIKast extends Component {
    state = {
        posts: [],
        isLoaded: false,
        perpage: 0
    }

    componentDidMount () {
        var config = {
            'Authorization': token,
            'Content-Type': 'application/json'
        }

        axios.get('https://apiexpeda.pipp.is/geturgency/71', {
            
            headers: config
        })
        .then(res => this.setState({ posts: res.data.data.urgency, isLoaded: true, perpage: res.data.data.per_page }))
        .catch(error => {
			const response = error.response;
            console.log(response);
        })
    }

    render() {
        const { posts, isLoaded} = this.state;
        console.log(posts);
        if (isLoaded) {
            return (
                <div>
                    <div className="fiftycent">
                        <strong>Hér eru upplysingar um kastið</strong>
                        <p><strong>Dagsetning</strong>: {posts[0].created}</p>
                        <p><strong>Þetta kast var</strong>: {posts[0].ended}</p>
                        <p><strong>Ráðgjöf: </strong>: {posts[0].reaction}</p>
                        <p><strong>Vegna</strong>: {posts[0].cause}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    Hleð inn...
                </div>
            )
        }
    }
}

export default withTranslation()(NaIKast);