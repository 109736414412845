import React, { Component } from 'react'
import SystemHeader from './SystemHeader';
import axios from 'axios';

import { getToken, removeAdminSession, getSession, getRefreshToken,setRefreshUserSession } from  '../../../Utils/admincommon'
const token = getToken();
const refreshtoken = getRefreshToken();
const session = getSession();
document.body.classList.add("admin");

export class Patients extends Component {

    state = { posts: [], isLoaded: false }
    componentDidMount () {
        axios.get('https://apiexpeda.pipp.is/getstaff',{
            headers: {
                'Authorization': token
            }
        }, { crossdomain: true })
        .then(res => this.setState({ posts: res.data.data.user, isLoaded: true }))
        .catch(error => {
			const response = error.response;
            if (response.status === 403) {
                const json = JSON.stringify({ refresh_token: refreshtoken });
                axios.patch('https://apiexpeda.pipp.is/adminsessions/'+session, json , {
                    headers: { 
                        'Authorization': token,
                        'Content-Type': 'application/json'
                    }
                }, { crossdomain: true })
                .then(response => {
                    console.log(response);
                    setRefreshUserSession(response.data.data.access_token, response.data.data.refresh_token);
                    window.location.reload();
                })
                .catch(error => {
                    const response = error.response;
                    console.log(response);
                })
            } else {
                removeAdminSession();
                window.location.reload();
            }
        })
    }

    render() {
        const { posts } = this.state;
        console.log(posts);
        console.log(token);
        return (
            <div>
                <SystemHeader title="Sjúklingar" />
                <div className="yfirlit">
                    
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nafn</th>
                                <th>Netfang</th>
                                <th>Símanúmer</th>
                                <th>Starfsheiti</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {posts.map(post =>
                                <tr>
                                    <td>{post.name} {posts.id}</td>
                                    <td>{post.netfang}</td>
                                    <td>{post.tel}</td>
                                    <td>{post.starfsheiti} {posts.starf}</td>
                                    <td><a href="#"><i class="fa-regular fa-ellipsis-stroke"></i></a></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

function getKyn(p) {
    if (p === 1) {
        return '<i class="fa-regular fa-mars"></i>';
    } else if (p === 2) {
        return '<i class="fa-regular fa-venus"></i>';
    } else {
        return '<i class="fa-regular fa-transgender"></i>';
    }
}

export default Patients