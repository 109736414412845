import React, { useState } from 'react';
import { setUserSession } from '../../Utils/Common';
import axios from 'axios';

function Login(props) {
    const [loading, setLoading] = useState(false);
    const [tab1active, setTab1active] = useState(false);
    const [tab2active, setTab2active] = useState(false);
    const [tab3active, setTab3active] = useState(true);
    const [myotpcode, setOtpcode] = useState('');
    const [mobileInput, setMobileInput] = useState(false);
    
    const username = useFormInput('');
    const password = useFormInput('');
    const farsimanumerSMS = useFormInput('');
    const SMSCode = useFormInput('');
    const [SMSCodeInput, setSMSCodeInput] = useState(false);
    const farsimanumer = useFormInput('');
    const [error, setError] = useState(null);

    const handleLoginRaf = () => {
        setError(null);
        setLoading(true);
    }

    const handleLoginSMS = () => {
        setError(null);
        setLoading(true);

        if (myotpcode) {
            console.log("kóði");
            var data = {
                'phonenum': farsimanumerSMS.value,
                'optcode': SMSCode.value
            }
    
            const headers = {
                'Content-Type': 'application/json'
            }
    
            axios.post('https://apiexpeda.pipp.is/checkotp',data, { headers: headers }, { crossdomain: true }).then(response => {
                setLoading(false);
                if (response.data.data.access_token) {
                    setUserSession(response.data.data.access_token, response.data.data.refresh_token , "", response.data.data.session_id, response.data.data.fullname, response.data.data.kyn);
                    props.history.push('/');
                } else {
                    setError("Error in login");
                }
            }).catch(error => {
                setLoading(false);
                setError("Innskráning tókst ekki!");
            });

        } else {
            console.log("ekki kodi");
            var dataPhone = {
                'phonenum': farsimanumerSMS.value
            }

            const headers = {
                'Content-Type': 'application/json'
            }

            axios.post('https://apiexpeda.pipp.is/sendotp',dataPhone, { headers: headers }, { crossdomain: true }).then(response => {
                setLoading(false);
                if (response.data.data.code) {
                    setOtpcode(response.data.data.code);
                    setSMSCodeInput(true);
                    setMobileInput(true);
                } else {
                    setError("Error in login");
                }
            }).catch(error => {
                setLoading(false);
                setError("Innskráning tókst ekki!");
            });
        }
    }

    const handleLogin = () => {
        setError(null);
        setLoading(true);
        var data = {
            'username': username.value,
            'password': password.value
        }

        const headers = {
            'Content-Type': 'application/json'
        }

        axios.post('https://apiexpeda.pipp.is/sessions', data, { headers: headers }, { crossdomain: true }).then(response => {
            setLoading(false);
            if (response.data.data.access_token) {
                setUserSession(response.data.data.access_token, response.data.data.refresh_token, username.value, response.data.data.session_id, response.data.data.fullname, response.data.data.kyn);
                props.history.push('/');
            } else {
                setError("Error in login");
            }
        }).catch(error => {
            setLoading(false);
            setError("Innskráning tókst ekki!");
        });

    }

    const showRafraen = () => {
        setTab1active(true);
        setTab2active(false);
        setTab3active(false);
    }

    const showSMS = () => {
        setTab1active(false);
        setTab2active(true);
        setTab3active(false);
    }

    const showKenni = () => {
        setTab1active(false);
        setTab2active(false);
        setTab3active(true);
    }

    return (
        <main>
            <div className="innskraningssida">
                <h3 className="login">Innskráning</h3>
                <h2 className="login">Sjúklingar</h2>
                <p className="login">Veldu hvað þér finnst best.</p>
                <ul className="loginmethod">
                    <li className={tab3active ? 'kenni active' : 'kenni'} onClick={showKenni}><i className="fa-light fa-pen-field"></i> Kennitala</li>
                    <li className={tab2active ? 'fasms active' : 'fasms'} onClick={showSMS}><i className="fa-light fa-mobile-retro"></i> Fá SMS</li>
                    <li className={tab1active ? 'rafraen active' : 'rafraen'} onClick={showRafraen}><i className="fa-light fa-sim-card"></i> Rafræn skilríki</li>
                </ul>

                <div className={tab1active ? 'tabcontent active' : 'tabcontent'} id="tab1">
                    <h3>Vinsamlegast skráðu þig inn með rafrænum skilríkjum.</h3>
                    <div style={{ marginTop: 10 }}>
                        <input type="text" {...farsimanumer} placeholder="sláðu inn símanúmerið þitt" />
                    </div>
                    <div style={{ marginTop: 5 }}><input type="button" className="loginbtn disabled" value={loading ? 'Hleð...' : 'Innskrá (ekki virkt)'} onClick={handleLoginRaf} disabled={true} /><br /></div>
                </div>
                <div className={tab2active ? 'tabcontent active' : 'tabcontent'} id="tab2">
                    <h3>Skráðu þig inn með SMS og fáðu lykilorð sent í símann.</h3>
                    <div style={{ marginTop: 10 }}>
                        <input type="text" {...farsimanumerSMS} placeholder="sláðu inn símanúmerið þitt" disabled={mobileInput} />
                    </div>
                    <div className={SMSCodeInput ? 'smscode active' : 'smscode'}>
                        <input type="text" {...SMSCode} placeholder="6 stafa kóði" />
                    </div>
                    <div style={{ marginTop: 5 }}><input type="button" className="loginbtn" value={loading ? 'Hleð...' : 'Innskrá'} onClick={handleLoginSMS} disabled={loading} /><br /></div>
                </div>
                <div className={tab3active ? 'tabcontent active' : 'tabcontent'} id="tab3">
                    <h3>Sláðu inn kennitöluna þína og lykilorð sem þú hefur valið</h3>
                    <div style={{ marginTop: 10 }}>
                        <input type="text" {...username} placeholder="Kennitala" />
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <input type="password" {...password} placeholder="Lykilorð" />
                    </div>
                    {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
                    <div style={{ marginTop: 5 }}><input type="button" className="loginbtn" value={loading ? 'Hleð...' : 'Innskrá'} onClick={handleLogin} disabled={loading} /><br /></div>
                    <div className="gleymt"><a href="/endurstilla-lykilord">Gleymt lykilorð!</a></div>
                </div>
            </div>
        </main>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}

export default Login;