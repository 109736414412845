import React from 'react'
import { NavLink } from 'react-router-dom';

import './Sidebar.css';

function SidebarAdmin() {
    return (
        <div className="sidebar">
            <h1><NavLink to="/admin/" activeClassName="selected">Fruma</NavLink></h1>
            <h4>Valmöguleikar</h4>
            <ul>
                <li><NavLink to="/admin/stjornbord" activeClassName="selected"><i className="fa-regular fa-gauge"></i> Yfirlit</NavLink></li>
                <li><NavLink to="/admin/samskipti" activeClassName="selected"><i className="fa-regular fa-inboxes"></i> Samskipti</NavLink></li>
                <li><NavLink to="/admin/patients" activeClassName="selected"><i className="fa-solid fa-hospital-user"></i> Sjúklingar</NavLink></li>
                <li><NavLink to="/admin/hae" activeClassName="selected"><i className="fa-light fa-folder-medical"></i> HAExpert</NavLink></li>
                <li><NavLink to="/admin/qol" activeClassName="selected"><i className="fa-light fa-folder-medical"></i> Quality Of Life</NavLink></li>
                <li><NavLink to="/admin/staff" activeClassName="selected"><i className="fa-sharp fa-solid fa-users"></i> Starfsfólk</NavLink></li>
            </ul>
            <ul className="actionBtn">
                <li className="btn "><a href="/admin/patient/skra"><i className="fa-solid fa-square-plus"></i> Skrá Sjúkling</a></li>
            </ul>
        </div>
    )
}

export default SidebarAdmin;